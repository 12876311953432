import { useEffect, useRef, useState } from 'react';
import { Input, InputProps, Loading, Select, SelectProps } from 'react-daisyui';

interface EditableCellProps {
  rowId: string;
  value: any;
  displayValue: any;
  updateData: (rowId: string, value: any) => Promise<void>;
}

export const EditableInputCell = ({
  value,
  updateData,
  inputProps,
  displayValue,
  rowId,
}: EditableCellProps & { inputProps?: Omit<InputProps, 'value' | 'onChange' | 'onBlur'> }) => {
  const [edit, setEdit] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const onBlur = async () => {
    let v = value;
    if (inputProps?.type === 'date' && typeof v === 'string') {
      v = v.substring(0, 10);
    }

    if (v !== inputValue) {
      setLoading(true);
      await updateData(rowId, inputValue);
      setLoading(false);
    }
    setEdit(false);
  };

  useEffect(() => {
    let v = value;

    if (inputProps?.type === 'date' && typeof v === 'string') {
      v = v.substring(0, 10);
    }

    setInputValue(v);
  }, [value]);

  return (
    <div
      ref={ref}
      className={
        'cursor-text border-x last:border-b hover:border-2 hover:border-primary-400 w-full h-full flex items-center px-4'
      }
      onClick={() => setEdit(true)}>
      {!edit && displayValue}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setEdit(false);
        }}>
        {edit && (
          <div className={'flex items-center'}>
            <Input
              {...inputProps}
              onBlur={onBlur}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className={'w-full'}
              size={'xs'}
              disabled={loading}
              autoFocus={true}
            />
            {loading && <Loading size={'xs'} />}
          </div>
        )}
      </form>
    </div>
  );
};

export const EditableSelectCell = ({
  value,
  updateData,
  displayValue,
  rowId,
  inputProps,
  options,
}: EditableCellProps & {
  inputProps?: Omit<SelectProps, 'value' | 'onChange' | 'onBlur'>;
  options: { label: any; value: any }[];
}) => {
  const [edit, setEdit] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const onBlur = async () => {
    if (value !== inputValue) {
      setLoading(true);
      await updateData(rowId, inputValue);
      setLoading(false);
    }
    setEdit(false);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      ref={ref}
      className={
        'cursor-text border-x last:border-b hover:border-2 hover:border-primary-400 w-full h-full flex items-center px-4'
      }
      onClick={() => setEdit(true)}>
      {!edit && displayValue}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setEdit(false);
        }}>
        {edit && (
          <div className={'flex items-center w-full'}>
            <Select
              {...inputProps}
              onBlur={onBlur}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className={'w-full'}
              size={'xs'}
              disabled={loading}
              autoFocus={true}>
              {options.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
            {loading && <Loading size={'xs'} />}
          </div>
        )}
      </form>
    </div>
  );
};
