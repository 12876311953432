// eslint-disable-next-line
import axios, { AxiosError, AxiosHeaderValue, AxiosResponse, Method } from 'axios';
import { sleep } from '../../utils/utils.ts';

class RequestBuilder {
  url: string;
  method: string;
  headers: Record<string, AxiosHeaderValue>;
  params: Record<string, unknown>;
  data: Record<string, unknown> | unknown[] | undefined;
  retryCount: number;
  initialDelay: number = 200;

  constructor() {
    this.url = '';
    this.method = 'get';
    this.headers = {};
    this.params = {};
    this.retryCount = 1;
  }

  setUrl(url: string) {
    this.url = url;
    return this;
  }

  setMethod(method: Method) {
    this.method = method;
    return this;
  }

  setHeaders(headers: Record<string, AxiosHeaderValue>) {
    this.headers = headers;
    return this;
  }

  setParams(params: Record<string, unknown>) {
    this.params = params;
    return this;
  }

  setData(data: Record<string, unknown> | any[]) {
    this.data = data;
    return this;
  }

  setRetryCount(retryCount: number) {
    this.retryCount = retryCount;
    return this;
  }

  async execute(): Promise<AxiosResponse> {
    let response: AxiosResponse;
    let delay: number = this.initialDelay as number;

    if (!this.url || !this.method) {
      throw new Error('`execute` method requires both `url` and `method` to be set.');
    }

    for (let i = 0; i <= this.retryCount; i++) {
      try {
        response = await axios({
          url: this.url,
          method: this.method,
          headers: this.headers,
          params: this.params,
          data: this.data,
        });
        return response;
      } catch (error: any) {
        if (error.isAxiosError) {
          const e = error as AxiosError;
          if (!e.status || ![408, 429, 500, 502, 503, 504].includes(e.status)) {
            throw error;
          }
        }

        if (i < this.retryCount) {
          await sleep(delay);
          // 10 seconds
          if (delay < 10000) {
            delay = 10000;
          } else {
            delay *= 2;
          }
        } else {
          throw error;
        }
      }
    }
    throw new Error('Failed to make request');
  }
}

export default RequestBuilder;
