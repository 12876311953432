import { Badge, Button, Divider, Input } from 'react-daisyui';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxStore.ts';
import {
  generateCopilotMessage,
  selectCopilotMessage,
  selectEditCoPilotQuestion,
  selectGeneratingMessageState,
  updateCoPilotMessage,
  updateCurrentCopilotMessage,
} from '../../../redux/slices/copilotSlice.ts';
import { parseException, stringToColor } from '../../../utils/utils.ts';
import { FormSelect } from '../../../components/basic/FormSelect.tsx';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

export const InputBar = () => {
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState('');
  const [holdingShift, setHoldingShift] = useState(false);
  const dispatch = useAppDispatch();
  const isGeneratingMessage = useAppSelector(selectGeneratingMessageState);
  const currentMessage = useAppSelector(selectCopilotMessage);
  const [filterOptions, setFilterOptions] = useState({ source: 'meetings', timePeriod: 'all' });
  const editCoPilotQuestion = useAppSelector(selectEditCoPilotQuestion);

  const exampleQuestions = [
    {
      question: 'What do BlackRock’s proxy voting guidelines say?',
      category: 'Proxy Voting',
    },
    {
      question: 'How did State Street vote at our last AGM?',
      category: 'Proxy Voting',
    },
    {
      question: 'When did we last meet with Vanguard and what did we discuss?',
      category: 'Investor Meetings',
    },
    {
      question: 'What are our investors’ key concerns and what changes do they want?',
      category: 'Investor Meetings',
    },
    {
      question: 'What ESG topics is BlackRock most focused on?',
      category: 'Sustainability',
    },
    {
      question: 'How can we improve our ESG disclosures?',
      category: 'Sustainability',
    },
    {
      question: 'What is our special meeting right threshold?',
      category: 'Company-Specific',
    },
    {
      question: 'Are there any activist funds in my shareholder base?',
      category: 'Company-Specific',
    },
  ];

  const submitMessage = async (e) => {
    e.preventDefault();
    if (error) {
      setError('');
    }
    const message = inputText.trim() || currentMessage?.query;

    if (!message) {
      return;
    }

    await submitMessageHelper(message);
  };

  const submitMessageHelper = async (message: string) => {
    try {
      if (message) {
        if (currentMessage?._id) {
          dispatch(
            updateCoPilotMessage({
              ...currentMessage,
              query: message,
              filters: { ...filterOptions },
            }),
          );
        } else {
          dispatch(
            updateCurrentCopilotMessage({
              query: message,
              answer: '',
              source: [],
              filters: { ...filterOptions },
            }),
          );
        }

        await dispatch(
          generateCopilotMessage({
            message,
            timePeriod: filterOptions.timePeriod,
            source: filterOptions.source,
            messageId: currentMessage?._id, // If exists, will be a regeneration
          }),
        ).unwrap();
        setInputText('');
      }
    } catch (e) {
      setInputText(message);
      setError(parseException(e, 'Failed to send message'));
    }
  };

  const handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === 'Shift' && !holdingShift) {
      setHoldingShift(true);
    } else if (ev.key === 'Enter') {
      if (!holdingShift) {
        submitMessage(ev);
      }
    }
  };

  const handleKeyUp = (ev: KeyboardEvent) => {
    if (ev.key === 'Shift' && holdingShift) {
      setHoldingShift(false);
    }
  };

  useEffect(() => {
    setFilterOptions({
      source: currentMessage?.filters?.source || 'all',
      timePeriod: currentMessage?.filters?.timePeriod || 'all',
    });
    setInputText(currentMessage?.query || '');
  }, [currentMessage, editCoPilotQuestion]);

  return (
    <>
      <div className={'flex pt-5 gap-1'}>
        Pull from{' '}
        <FormSelect
          size={'xs'}
          options={[
            { value: 'All Sources', id: 'all' },
            { value: 'Meetings', id: 'meetings' },
            { value: 'Investor Documents', id: 'investor_documents' },
            //{ value: 'Internet', id: 'internet' },
            //{ value: 'Company Documents', id: 'company_documents' },
          ]}
          className={'w-max'}
          value={filterOptions.source}
          onChange={(e) => setFilterOptions({ ...filterOptions, source: e.target.value })}
        />
        {/*from
        <FormSelect
          size={'xs'}
          options={[
            { value: 'all time', id: 'all' },
            { value: 'last 30 days', id: '30d' },
            { value: 'last 3 months', id: '3m' },
            { value: 'last 1 year', id: '1y' },
          ]}
          className={'w-max'}
          value={filterOptions.timePeriod}
          onChange={(e) => setFilterOptions({ ...filterOptions, timePeriod: e.target.value })}
        />*/}
        {currentMessage?._id &&
        JSON.stringify(currentMessage.filters) !== JSON.stringify(filterOptions) &&
        !isGeneratingMessage &&
        !editCoPilotQuestion ? (
          <Button className={'ml-4'} color={'primary'} size={'xs'} onClick={submitMessage}>
            Regenerate
          </Button>
        ) : null}
      </div>

      {(!currentMessage?.query || editCoPilotQuestion) && !isGeneratingMessage ? (
        <div
          className={
            'flex items-center justify-center mb-8 w-full mx-auto relative mt-4 box-border'
          }>
          <SparklesIcon className={'w-8 absolute left-4'} />
          <Input
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className={'min-h-[50px] text-lg w-full bg-white rounded-xl pr-20 pl-16'}
            color={'primary'}
            placeholder={'Ask away...'}
            onKeyDownCapture={handleKeyDown}
            onKeyUpCapture={handleKeyUp}
          />
          <Button
            disabled={!inputText}
            startIcon={<ArrowRightIcon className={'min-w-6 w-6'} />}
            color={'primary'}
            className={'absolute right-3'}
            onClick={submitMessage}
            shape={'circle'}
            size={'sm'}
          />
          {error && <p className={'text-error text-center absolute bottom-0 -mb-8'}>{error}</p>}
        </div>
      ) : (
        <h3 className={'text-2xl font-semibold mt-5 mb-8'}>{currentMessage?.query || inputText}</h3>
      )}

      {!currentMessage?.query && !isGeneratingMessage ? (
        <div className={'w-full flex flex-col gap-2 bg-white rounded-2xl p-8 px-10 border'}>
          <h4 className={'text-2xl font-bold flex gap-2'}>
            <SparklesIcon className={'w-6 text-primary-500'} />
            Ask Shrub
          </h4>
          <p>
            Ask any question about your company, institutional shareholders, or shareholder meetings
            and receive a source response from our proprietary AI assistant.
          </p>

          <Divider />

          <h4 className={'text-xl font-bold'}>Try Asking</h4>
          <div className={'mx-auto grid grid-cols-2 gap-2'}>
            {exampleQuestions.map((q, i) => {
              return (
                <div
                  style={{ borderColor: stringToColor(q.category) }}
                  key={i}
                  onClick={() => submitMessageHelper(q.question)}
                  className={
                    'p-2 border rounded-xl cursor-pointer hover:bg-gray-100 active:bg-gray-50 flex justify-between'
                  }>
                  {q.question}
                  <Badge
                    size={'sm'}
                    className={'text-gray-600 text-xs mt-1 text-nowrap'}
                    style={{
                      borderColor: stringToColor(q.category),
                      background: stringToColor(q.category, 0.05),
                    }}>
                    {q.category}
                  </Badge>
                </div>
              );
            })}
          </div>
          <p className={'text-center text-xs mt-8 font-semibold text-gray-400 tracking-wide'}>
            Shrub AI can make mistakes. Please verify information before making any important
            decisions.
          </p>
        </div>
      ) : null}
    </>
  );
};
