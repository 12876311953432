import { useAppDispatch, useAppSelector } from '../../../redux/reduxStore.ts';
import {
  selectCopilotMessage,
  selectEditCoPilotQuestion,
  selectGeneratingMessageState,
  toggleEditCoPilotQuestion,
  updateCoPilotMessage,
} from '../../../redux/slices/copilotSlice.ts';
import { NewspaperIcon, PencilIcon, SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Divider, Skeleton } from 'react-daisyui';
import { patchCopilotMessage } from '../../../services/copilot.service.ts';
import { selectOrganization } from '../../../redux/slices/organizationSlice.ts';
import { MessageRating } from './MessageRating.tsx';
import { SourceRow } from './SourceRow.tsx';

export const MessagesContainer = () => {
  const message = useAppSelector(selectCopilotMessage);
  const organization = useAppSelector(selectOrganization);
  const isGeneratingMessage = useAppSelector(selectGeneratingMessageState);
  const editCoPilotQuestion = useAppSelector(selectEditCoPilotQuestion);
  const dispatch = useAppDispatch();

  function formatTextToHtml(text: string) {
    const annotationRegex = /【[^\]]+?】/g; // Strips out annotations
    return text.replace(annotationRegex, '').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  }

  const handleRating = async (rating: -1 | 1) => {
    if (!message) {
      return;
    }

    dispatch(
      updateCoPilotMessage({
        ...message,
        rating,
      }),
    );

    await patchCopilotMessage(organization!._id, message._id, { rating });
  };

  const handleEditQuestion = () => {
    dispatch(toggleEditCoPilotQuestion());
  };

  const getSources = () => {
    const set = new Set<string>();
    return (
      message?.sources?.filter((s) => {
        if ('storageUrl' in s) {
          const storageUrl = s.storageUrl;

          if (set.has(storageUrl)) {
            return false;
          }

          set.add(storageUrl);
        }
        return true;
      }) || []
    );
  };

  return (
    <div
      className={
        'relative flex-1 overflow-auto w-full mx-auto flex flex-col basis-0 items-center py-4 pb-0 gap-10'
      }>
      {isGeneratingMessage || message?.query ? (
        <div className={'w-full bg-white border rounded-2xl py-8 px-10 mt-10'}>
          <h2 className={'flex gap-2 text-2xl font-bold pb-4'}>
            <SparklesIcon className={'w-8 text-primary-500'} />
            {!isGeneratingMessage ? 'Answer & Summary' : 'Generating...'}
          </h2>

          {isGeneratingMessage && (
            <>
              <Skeleton className={'w-1/2 h-5 mb-3'} />
              <Skeleton className={'w-full h-5 mb-3'} />
              <Skeleton className={'w-full h-5 mb-3'} />
              <Skeleton className={'w-full h-5 mb-3'} />
              <Skeleton className={'w-full h-5 mb-3'} />
            </>
          )}

          {!isGeneratingMessage && (
            <>
              <p dangerouslySetInnerHTML={{ __html: formatTextToHtml(message?.answer || '') }} />
              <div className={'flex gap-2 mt-10 items-center'}>
                <MessageRating rating={message?.rating} onRatingChange={handleRating} />{' '}
                <span>|</span>
                <Button
                  size={'sm'}
                  variant={'link'}
                  startIcon={
                    editCoPilotQuestion ? (
                      <XMarkIcon className={'w-4'} />
                    ) : (
                      <PencilIcon className={'w-4'} />
                    )
                  }
                  className={`p-0 ${editCoPilotQuestion ? 'text-error' : 'text-gray-500'}`}
                  onClick={handleEditQuestion}>
                  {editCoPilotQuestion ? 'Cancel ' : ''}
                  Edit Question
                </Button>
              </div>
            </>
          )}

          {!isGeneratingMessage && !!message?.sources?.length ? (
            <>
              <Divider />
              <h2 className={'flex gap-2 text-2xl font-bold pb-4 mt-5'}>
                <NewspaperIcon className={'w-8 text-primary-500'} />
                Sources and Citations
              </h2>

              {getSources().map((source, i) => (
                <SourceRow key={i} source={source} />
              ))}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
