import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';

interface Props {
  title: string | ReactNode;
  icon?: string | ReactNode;
  lightMode?: boolean;
  titleActionButton?: ReactNode;
  children?: ReactNode;
  className?: HTMLDivElement['className'];
}

export const CardSection = ({
  title,
  className = '',
  children,
  icon,
  titleActionButton,
  lightMode = true,
}: Props) => {
  return (
    <div
      className={twMerge(
        'w-full p-0 bg-white shadow-lg rounded-2xl flex flex-col border ',
        className,
      )}>
      <div
        className={`rounded-t-2xl py-2 px-3 border-b font-semibold flex justify-start items-center ${
          lightMode ? 'bg-secondary-100' : 'bg-secondary-900 text-gray-50'
        }`}>
        {typeof icon === 'string' ? (
          <img className={'mr-3'} height={'48px'} width={'48px'} src={icon} alt={'Icon'} />
        ) : (
          <span className={'mr-3'}>{icon}</span>
        )}
        <h4
          className={
            'whitespace-nowrap overflow-ellipsis overflow-hidden text-sm sm:text-lg xl:text-xl'
          }>
          {title}
        </h4>
        <span className={'flex-1'} />
        {titleActionButton}
      </div>
      {children}
    </div>
  );
};
