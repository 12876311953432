import axios from 'axios';
import { SHRUB_API_URL } from '../utils/constants.ts';
import { fetchAuthSession } from 'aws-amplify/auth';

/*
 * This interceptor will add the cognito token to any request that is going to Pigeon api
 * */
axios.interceptors.request.use(
  async (request) => {
    if (request.url && request.url.includes(SHRUB_API_URL)) {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};

      // @ts-ignore
      request.headers = {
        ...request.headers,
        Authorization: accessToken?.toString(),
      };
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);
