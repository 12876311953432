import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';
import { RootState } from '../reduxStore.ts';
import {
  getOrganization,
  getOrganizationActionItems,
  getOrganizationResources,
  getOrganizationUsers,
} from '../../services/organization.service.ts';
import { IOrganization, IResource } from '../../typescript/IOrganization.ts';
import { IActionItem } from '../../typescript/IActionItem.ts';
import { IMember } from '../../typescript/IUser.ts';
import * as LZString from 'lz-string';
import { IInvestor } from '../../typescript/IInvestorDetails.ts';

type InitialState = {
  organization: IOrganization | null;
  initialLoad: boolean;
  requestStatus: Record<string, RequestStates>;
  investors: IInvestor[];
  actionItems: IActionItem[];
  users: IMember[];
  resources: IResource[];
};

const initialState: InitialState = {
  organization: null,
  initialLoad: false,
  requestStatus: {
    organization: RequestStates.idle,
    investors: RequestStates.idle,
    actionItems: RequestStates.idle,
    users: RequestStates.idle,
  },
  investors: localStorage.getItem('INVESTORS')
    ? (JSON.parse(LZString.decompressFromUTF16(localStorage.getItem('INVESTORS') as string)) as [])
    : [],
  actionItems: [],
  users: [],
  resources: [],
};

export const fetchOrganization = createAsyncThunk(
  'organization/fetchOrg',
  async (id: string, { rejectWithValue }) => {
    try {
      return getOrganization(id);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { organizationSlice }: any = getState();
      if (organizationSlice.requestStatus.organization === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchMembers = createAsyncThunk(
  'organization/fetchMembers',
  async (_: undefined, { rejectWithValue, getState }) => {
    try {
      const { organizationSlice } = getState() as RootState;
      return getOrganizationUsers(organizationSlice?.organization?._id as string);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { organizationSlice }: any = getState();
      if (organizationSlice.requestStatus.users === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchOrganizationActionItems = createAsyncThunk(
  'organization/fetchOrganizationActionItems',
  async (_: undefined, { rejectWithValue, getState }) => {
    try {
      const { organizationSlice } = getState() as RootState;
      return getOrganizationActionItems(organizationSlice?.organization?._id as string);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { organizationSlice }: any = getState();
      if (organizationSlice.requestStatus.actionItems === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchMeetingActionItems = createAsyncThunk(
  'organization/fetchMeetingActionItems',
  async (meetingId: string, { rejectWithValue, getState }) => {
    try {
      const { organizationSlice } = getState() as RootState;
      return getOrganizationActionItems(organizationSlice?.organization?._id as string, meetingId);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { organizationSlice }: any = getState();
      if (organizationSlice.requestStatus.actionItems === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchResources = createAsyncThunk(
  'organization/fetchResources',
  async (meetingId: string | undefined, { rejectWithValue, getState }) => {
    const { organizationSlice }: any = getState();
    try {
      if (!organizationSlice.organization._id) {
        return null;
      }
      return getOrganizationResources(organizationSlice.organization._id, meetingId);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (meetingId, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { organizationSlice } = getState() as RootState;
      if (
        organizationSlice.requestStatus[`resources-${meetingId || 'all'}`] === RequestStates.pending
      ) {
        return false;
      }
    },
  },
);

export const organizationSlice = createSlice({
  name: 'organizationSlice',
  initialState: initialState,
  reducers: {
    resetOrganization: () => {
      return initialState;
    },
    removeResource: (state, action) => {
      state.resources = state.resources.filter((r) => r.key !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.organization = action.payload;
        state.requestStatus.organization = RequestStates.idle;
        state.initialLoad = true;
      })
      .addCase(fetchOrganization.pending, (state) => {
        state.requestStatus.organization = RequestStates.pending;
      })
      .addCase(fetchOrganization.rejected, (state) => {
        state.requestStatus.organization = RequestStates.idle;
      });

    builder
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.requestStatus.users = RequestStates.idle;
      })
      .addCase(fetchMembers.pending, (state) => {
        state.requestStatus.users = RequestStates.pending;
      })
      .addCase(fetchMembers.rejected, (state) => {
        state.requestStatus.users = RequestStates.idle;
      });

    builder
      .addCase(fetchOrganizationActionItems.fulfilled, (state, action) => {
        state.actionItems = action.payload;
        state.requestStatus.actionItems = RequestStates.idle;
      })
      .addCase(fetchOrganizationActionItems.pending, (state) => {
        state.requestStatus.actionItems = RequestStates.pending;
      })
      .addCase(fetchOrganizationActionItems.rejected, (state) => {
        state.requestStatus.actionItems = RequestStates.idle;
      });

    builder
      .addCase(fetchMeetingActionItems.fulfilled, (state, action) => {
        const temp = state.actionItems.filter((i) => i.meetingId !== action.meta.arg);
        state.actionItems = [...temp, ...action.payload];
        state.requestStatus.actionItems = RequestStates.idle;
      })
      .addCase(fetchMeetingActionItems.pending, (state) => {
        state.requestStatus.actionItems = RequestStates.pending;
      })
      .addCase(fetchMeetingActionItems.rejected, (state) => {
        state.requestStatus.actionItems = RequestStates.idle;
      });

    builder
      .addCase(fetchResources.fulfilled, (state, action) => {
        const attachments = state.resources;
        if (action.meta.arg) {
          state.resources = [
            ...attachments.filter((m) => m.meetingId !== action.meta.arg),
            ...action.payload,
          ];
        } else {
          state.resources = action.payload;
        }
        state.requestStatus[`resources-${action.meta?.arg || 'all'}`] = RequestStates.idle;
      })
      .addCase(fetchResources.pending, (state, action) => {
        state.requestStatus[`resources-${action.meta?.arg || 'all'}`] = RequestStates.pending;
      })
      .addCase(fetchResources.rejected, (state, action) => {
        state.requestStatus[`resources-${action.meta?.arg || 'all'}`] = RequestStates.idle;
      });

    // Global RESET
    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { removeResource } = organizationSlice.actions;

// Selectors
export const selectOrganization = (state: RootState) => state.organizationSlice.organization;
export const selectMembers = (state: RootState) => state.organizationSlice.users;
export const selectOrganizationInvestorById = (id: string) => (state: RootState) =>
  state.organizationSlice.investors.find((i) => i._id === id);
export const selectRequestStatusById = (id: string) => (state: RootState) =>
  state.organizationSlice.requestStatus?.[id];
export const selectOrganizationInitialLoad = (state: RootState) => state.userSlice.initialLoad;
export const selectActionItems = (state: RootState) => state.organizationSlice.actionItems;
export const selectMeetingActionItems = (meetingId: string) =>
  createSelector(
    (state: RootState) => state.organizationSlice.actionItems,
    (actionItems) => actionItems.filter((ai) => ai.meetingId === meetingId),
  );
export const selectAllResources = (state: RootState) => state.organizationSlice.resources;
export const selectResourcesByMeetingId = createSelector(
  [selectAllResources, (_, meetingId: string) => meetingId],
  (resource, meetingId) => resource.filter((resource) => resource.meetingId === meetingId),
);

export default organizationSlice.reducer;
