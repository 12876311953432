import { Button } from 'react-daisyui';
import { IoWarningOutline } from 'react-icons/io5';
import { LANDING_PAGE_URL } from '../../../utils/constants.ts';
import { useAppDispatch } from '../../../redux/reduxStore.ts';
import { resetRedux } from '../../../redux/custom/customActions.ts';
import { signOut } from 'aws-amplify/auth';

export const NoOrganizations = () => {
  const dispatch = useAppDispatch();

  const onBackToLogin = async () => {
    try {
      await signOut();
      dispatch(resetRedux());
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <>
      <IoWarningOutline className={'text-[5rem] text-orange-400'} />
      <h1 className={'text-4xl mt-5'}>Warning</h1>

      <p className={'mt-4 mb-2 text-sm text-center'}>
        Your account is not associated to any organizations in our system. Please contact us if you
        would like a Demo.
      </p>

      <Button
        color={'primary'}
        type={'button'}
        fullWidth={true}
        className={'mt-4 text-white'}
        onClick={() => window.open(LANDING_PAGE_URL)}>
        Contact Us
      </Button>
      <Button onClick={onBackToLogin} variant={'link'}>
        Back to Login
      </Button>
    </>
  );
};
