import axios from 'axios';
import { SHRUB_API_URL } from '../utils/constants.ts';

export const getCopilotMessages = async (orgId: string) => {
  try {
    const { data } = await axios.get(`${SHRUB_API_URL}/organizations/${orgId}/copilot/messages`);
    return data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
};

export const postCopilotMessage = async (orgId: string, payload: Record<any, any>) => {
  try {
    const { data } = await axios.post(
      `${SHRUB_API_URL}/organizations/${orgId}/copilot/messages`,
      payload,
    );
    return data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
};

export const regenerateCopilotMessage = async (
  orgId: string,
  messageId: string,
  payload: Record<any, any>,
) => {
  try {
    const { data } = await axios.post(
      `${SHRUB_API_URL}/organizations/${orgId}/copilot/messages/${messageId}`,
      payload,
    );
    return data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
};

export const patchCopilotMessage = async (
  orgId: string,
  messageId: string,
  payload: Record<any, any>,
) => {
  try {
    const { data } = await axios.patch(
      `${SHRUB_API_URL}/organizations/${orgId}/copilot/messages/${messageId}`,
      payload,
    );
    return data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
};
