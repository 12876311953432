import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { TModalInterface } from '../../typescript/basicTypes.ts';
import { Button } from 'react-daisyui';
import { CgClose } from 'react-icons/cg';
import { twMerge } from 'tailwind-merge';

interface Props extends TModalInterface {
  children: React.ReactNode;
  title?: string | ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  footerControls?: {
    confirmText?: string;
    cancelText?: string;
    onConfirm?: () => Promise<void>;
    onCancel?: () => void;
  };
  disableBackdrop?: boolean;
  hideCloseButton?: boolean;
}

export const ModalContainer = ({
  open,
  onClose,
  title,
  footerControls,
  hideCloseButton,
  children,
  disableBackdrop = false,
  size = 'md',
}: Props) => {
  const ref = useRef<HTMLDialogElement>(null);
  const [loading, setLoading] = useState(false);

  function getModalWidth() {
    return {
      xs: 'max-w-[20%]',
      sm: 'max-w-[30%]',
      md: 'max-w-[40%]',
      lg: 'max-w-[50%]',
      xl: 'max-w-[70%]',
    }[size];
  }

  const onConfirm = async () => {
    if (!footerControls?.onConfirm) {
      return;
    }

    try {
      setLoading(true);
      await footerControls.onConfirm();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ref.current) {
      if (open) {
        ref.current.showModal();
      } else {
        ref.current.close();
      }
    }
  }, [ref, open]);

  return (
    <dialog className={'modal'} ref={ref}>
      <div className={twMerge(`modal-box ${getModalWidth()}`, 'overflow-y-visible')}>
        <div className={'relative flex items-center border-b pb-3'}>
          <h4 className={'text-2xl'}>{title}</h4>
          {!hideCloseButton && (
            <Button
              color={'ghost'}
              shape={'circle'}
              className={'absolute right-2 top-0'}
              size={'sm'}
              onClick={onClose}>
              <CgClose className={'w-[24px] h-[24px] text-primary-900'} />
            </Button>
          )}
        </div>

        <div>{children}</div>

        {footerControls && (
          <div className="modal-action">
            <div className={'flex flex-1 gap-4'}>
              <span className={'flex-1'} />
              {footerControls ? (
                <>
                  {footerControls.onCancel && (
                    <Button
                      variant={'outline'}
                      color={'secondary'}
                      onClick={!loading ? footerControls.onCancel : undefined}>
                      {footerControls.cancelText || 'Cancel'}
                    </Button>
                  )}
                  {footerControls.onConfirm && (
                    <Button loading={loading} color={'success'} onClick={onConfirm}>
                      {footerControls.confirmText || 'Confirm'}
                    </Button>
                  )}
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {!disableBackdrop && (
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => onClose()}>close</button>
        </form>
      )}
    </dialog>
  );
};
