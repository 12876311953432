import VerificationInput from 'react-verification-input';
import { Button, Loading } from 'react-daisyui';
import { useState } from 'react';
import { confirmSignUp, resendSignUpCode, signIn } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';

interface Props {
  email: string;
  password: string;
}

export const ConfirmationForm = ({ email, password }: Props) => {
  const [codeSent, setCodeSent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleResendCode = async () => {
    try {
      setCodeSent(true);
      await resendSignUpCode({ username: email });
      enqueueSnackbar('Please check your email');
    } catch (e) {
      console.log('ERROR', e);
      setCodeSent(false);
    }
  };

  const handleConfirmCode = async (code: string) => {
    try {
      setLoading(true);
      await confirmSignUp({
        username: email,
        confirmationCode: code,
      });

      await signIn({
        username: email,
        password,
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Incorrect Code', { variant: 'error' });
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className={'text-3xl'}>Confirm Email</h1>

      <p className={'text-center mt-6'}>
        You should receive a 6 digit code in your email <b>{email}</b>
      </p>

      {!loading && (
        <VerificationInput containerProps={{ className: 'my-10' }} onComplete={handleConfirmCode} />
      )}

      {loading && <Loading size={'lg'} className={'mx-auto block my-10'} />}

      <Button disabled={codeSent} variant={'link'} onClick={handleResendCode}>
        Resend Code
      </Button>

      {codeSent && (
        <p className={'text-center text-xs text-slate-400 mt-10 -mb-10'}>
          Please check your spam folder if you do not see an email
        </p>
      )}
    </>
  );
};
