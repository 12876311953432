import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';
import { RootState } from '../reduxStore.ts';
import * as LZString from 'lz-string';
import { IOutreaches } from '../../typescript/IOutreaches.ts';
import { getOutreaches } from '../../services/outreaches.service.ts';
import { mergeArrays } from '../../utils/utils.ts';

type InitialState = {
  requestStatus: Record<string, RequestStates>;
  outreaches: IOutreaches[];
  initialLoads: Record<string, boolean>;
};

const initialState: InitialState = {
  requestStatus: {},
  initialLoads: {},
  outreaches: localStorage.getItem('INVESTOR_OUTREACHES')
    ? (JSON.parse(
        LZString.decompressFromUTF16(localStorage.getItem('INVESTOR_OUTREACHES') as string),
      ) as [])
    : [],
};

export const fetchOutreaches = createAsyncThunk(
  'outreaches/fetchMeetings',
  async (filters: undefined | Partial<IOutreaches>, { rejectWithValue }) => {
    try {
      return getOutreaches(filters);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { outreachesSlice } = getState() as RootState;
      if (outreachesSlice.requestStatus['all'] === RequestStates.pending) {
        return false;
      }
    },
  },
);

const outreachesSlice = createSlice({
  name: 'outreachesSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOutreaches.fulfilled, (state, action) => {
        if (action.meta.arg) {
          state.outreaches = mergeArrays(state.outreaches, action.payload);
        } else {
          state.outreaches = action.payload;
        }

        const compressed = LZString.compressToUTF16(JSON.stringify(action.payload));
        // Store it in localStorage
        localStorage.setItem('INVESTOR_OUTREACHES', compressed);
        state.requestStatus['all'] = RequestStates.idle;
        if (!state.initialLoads['all']) {
          state.initialLoads['all'] = true;
        }
      })
      .addCase(fetchOutreaches.pending, (state) => {
        state.requestStatus['all'] = RequestStates.pending;
      })
      .addCase(fetchOutreaches.rejected, (state) => {
        state.requestStatus['all'] = RequestStates.idle;
        if (!state.initialLoads['all']) {
          state.initialLoads['all'] = true;
        }
      });

    // Global RESET
    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Action creators are generated for each case reducer function
// export const {} = outreachesSlice.actions;

// Selectors
export const selectOutreaches = (state: RootState) => state.outreachesSlice.outreaches;

export const selectOutreachesByInvestorId = createSelector(
  [selectOutreaches, (_, investorId) => investorId],
  (outreaches, investorId) => outreaches.filter((o) => o.investorId === investorId),
);

export default outreachesSlice.reducer;
