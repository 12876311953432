import { useNavigate } from 'react-router-dom';
import { Button } from 'react-daisyui';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className={'flex flex-col items-center justify-center h-full'}>
      <h1
        className={'absolute top-[10%] text-4xl font-semibold text-gray-400 sm:mb-16 text-center'}>
        404
        <br />
      </h1>
      <h1 className={'text-xl sm:text-3xl text-center translate-y-[-50%] text-gray-800'}>
        Page you are looking for does not exist. <br /> <br /> Please return.
      </h1>

      <Button
        className={'mt-10 text-white'}
        color={'primary'}
        onClick={() => navigate('/', { replace: true })}>
        Home
      </Button>
    </div>
  );
};
