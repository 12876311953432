// eslint-disable-next-line import/named
import { Textarea, TextareaProps } from 'react-daisyui';
import { ReactNode } from 'react';

interface Props extends TextareaProps {
  label?: string;
  hint?: string | ReactNode;
  fullWidth?: boolean;
  error?: boolean;
}

export const FormTextarea = ({
  label,
  hint,
  fullWidth = false,
  error,
  required,
  ...other
}: Props) => {
  return (
    <div className={`form-control w-full ${fullWidth ? '' : 'max-w-xs'}`}>
      {label && (
        <label className="label pb-1">
          <span className={`label-text ${required && "after:content-['*'] after:text-red-600"}`}>
            {label}
          </span>
        </label>
      )}
      <Textarea required={required} {...other} />
      {hint && (
        <>
          {typeof hint === 'string' ? (
            <label className={`label flex font-medium`}>
              <span className={'flex-1'} />
              <span className={`label-text-alt ${error ? 'text-error' : 'text-gray-400'}`}>
                {hint}
              </span>
            </label>
          ) : (
            <span>{hint}</span>
          )}
        </>
      )}
    </div>
  );
};
