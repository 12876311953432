import { SHRUB_API_URL } from '../utils/constants.ts';
import { reduxStore } from '../redux/reduxStore.ts';
import RequestBuilder from './utils/RequestBuilder.ts';
import { IOutreaches } from '../typescript/IOutreaches.ts';

const getUrl = () => {
  const { organizationSlice } = reduxStore.getState();
  return `${SHRUB_API_URL}/organizations/${organizationSlice.organization!._id}`;
};

export const getOutreaches = async (
  filters?: undefined | Partial<IOutreaches>,
): Promise<IOutreaches[]> => {
  const { data } = await new RequestBuilder()
    .setUrl(`${getUrl()}/outreaches` + (filters?._id ? `/${filters._id}` : ''))
    .setMethod('get')
    .execute();

  return data;
};

export const postOutreaches = async (payload: any): Promise<void> => {
  const { data } = await new RequestBuilder()
    .setUrl(`${getUrl()}/outreaches`)
    .setMethod('post')
    .setData(payload)
    .execute();

  return data;
};

export const putOutreaches = async (id: string, payload: any): Promise<void> => {
  const { data } = await new RequestBuilder()
    .setUrl(`${getUrl()}/outreaches/${id}`)
    .setMethod('put')
    .setData(payload)
    .execute();

  return data;
};

export const patchOutreaches = async (id: string, payload: any): Promise<void> => {
  const { data } = await new RequestBuilder()
    .setUrl(`${getUrl()}/outreaches/${id}`)
    .setMethod('patch')
    .setData(payload)
    .execute();

  return data;
};
