import { Badge, Input, InputProps } from 'react-daisyui';
import { ReactNode, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props extends Omit<InputProps, 'onChange' | 'value'> {
  label?: string;
  hint?: string | ReactNode;
  fullWidth?: boolean;
  error?: boolean;
  onEntryChange: (arg: string[]) => void;
  entries: string[];
}

export const InputWBadges = ({
  label,
  hint,
  fullWidth = false,
  error,
  required,
  entries,
  onEntryChange,
  ...other
}: Props) => {
  const [entry, setEntry] = useState('');

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (entry) {
        if (entry.trim()) {
          onEntryChange([...entries, entry.trim()]);
        }
        setEntry('');
      }
    }
  };

  const handleEntryRemove = async (arg: string) => {
    onEntryChange([...entries.filter((e) => e.toLowerCase() !== arg.toLowerCase())]);
  };

  return (
    <div className={`form-control w-full ${fullWidth ? '' : 'max-w-xs'}`}>
      {label && (
        <label className="label pb-1">
          <span className={`label-text ${required && "after:content-['*'] after:text-red-600"}`}>
            {label}
          </span>
        </label>
      )}
      <Input
        {...other}
        onChange={(e) => setEntry(e.target.value)}
        value={entry}
        onKeyDown={handleKeyDown}
        required={entries.length ? false : required}
      />
      {entries.length ? (
        <div className={'flex gap-2 flex-wrap mt-2'}>
          {entries.map((opt, i) => (
            <Badge color={'neutral'} className={'whitespace-nowrap'} key={i}>
              {opt}
              <XMarkIcon
                className={'w-3 ml-2 cursor-pointer'}
                onClick={() => handleEntryRemove(opt)}
              />
            </Badge>
          ))}
        </div>
      ) : null}
      {hint && (
        <>
          {typeof hint === 'string' ? (
            <label className={`label flex font-medium p-1`}>
              <span className={'flex-1'} />
              <span className={`label-text-alt ${error ? 'text-error' : 'text-gray-400'}`}>
                {hint}
              </span>
            </label>
          ) : (
            <span>{hint}</span>
          )}
        </>
      )}
    </div>
  );
};
