import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../reduxStore.ts';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';

type InitialState = {
  shareholderAlerts: {
    investorId: string;
    alert: string;
    _id: string;
    priority: number;
    lastEngagement: string;
    investor?: Record<any, any>;
  }[];
};

const initialState: InitialState = {
  shareholderAlerts: [
    /*    {
      _id: '1',
      investorId: '6684867c9879a0d66786ee2a',
      alert:
        'You have not met with Vanguard since Dec 4th, 2023. May be a good time to meet with them.',
      priority: 1,
      lastEngagement: 'Dec 4th, 2023',
    },
    {
      _id: '2',
      investorId: '6684867c9879a0d66786ef32',
      alert: 'D. E. Shaw group is a known activist and they have increased their O/S by 2%.',
      priority: 0,
      lastEngagement: '',
    },
    {
      _id: '3',
      investorId: '6684867c9879a0d66786ee2e',
      alert: 'Berkshire Hathaway has reduced their O/S by 3%.',
      priority: 0,
      lastEngagement: '',
    },
    {
      _id: '4',
      investorId: '6684867c9879a0d66786ee2b',
      alert: 'BlackRock has published their most recent Proxy Voting Guidelines.',
      priority: 2,
      lastEngagement: 'May 5th, 2024',
    },
    {
      _id: '5',
      investorId: '6684867c9879a0d66786eea0',
      alert:
        'Dimensional Fund Advisors formerly #264 increased their stake this quarter and is now #20.',
      priority: 1,
      lastEngagement: '',
      investor: {
        name: 'Dimensional Fund Advisors',
        totalSharesAmount: 1500000,
        rank: 20,
      },
    },*/
  ],
};

const shareholderAlertsSlice = createSlice({
  name: 'shareholderAlertsSlice',
  initialState: initialState,
  reducers: {
    removeShareholderAlert: (state, action) => {
      state.shareholderAlerts = state.shareholderAlerts.filter((a) => a._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { removeShareholderAlert } = shareholderAlertsSlice.actions;

// Selectors
export const selectShareholderAlerts = (state: RootState) =>
  state.shareholderAlertsSlice.shareholderAlerts;

export default shareholderAlertsSlice.reducer;
