import { ENVIRONMENT } from '../utils/constants.ts';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import ls from '@livesession/sdk';

const SENTRY_DSN =
  'https://0d31206ad2c49a3e4153ac2d2f084f2b@o4507727027503104.ingest.us.sentry.io/4507727034515456';

if (ENVIRONMENT && ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/dev.goshrub\.com\/api/,
      /^https:\/\/prod.goshrub\.com\/api/,
    ],
    beforeSend: async (event) => {
      const createdSessionURL = ls.getSessionURL();
      const wait = function wait(ms) {
        return new Promise(function (r) {
          return setTimeout(r, ms);
        });
      };
      return wait(3000).then(() => {
        if (!event.extra) {
          event.extra = {};
        }

        event.extra['sessionURL'] = createdSessionURL;

        return event;
      });
    },
    // Session Replay
    replaysSessionSampleRate: ENVIRONMENT === 'production' ? 0.1 : 0.6, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.setExtra('sessionURL', '');
}
