import { BasicLayout } from '../../components/layout/BasicLayout.tsx';
import { ShareholderEngagementSummarySection } from './sections/ShareholderEngagementSummarySection.tsx';
import { TopicAnalysisSummary } from './sections/TopicAnalysisSummary.tsx';
import KeyActionSteps from '../EngagementStrategyPage/components/KeyActionSteps.tsx';
import { ActionItemsSection } from './sections/ActionItemsSection.tsx';
import { RequiredDatesModal } from '../../components/modals/RequiredDatesModal.tsx';
import { ShareholderAlerts } from './sections/ShareholderAlerts.tsx';
import { useAppDispatch, useAppSelector } from '../../redux/reduxStore.ts';
import { selectOrganization } from '../../redux/slices/organizationSlice.ts';
import { useEffect } from 'react';
import { fetchDashboardStats } from '../../redux/slices/applicationSlice.ts';

export const DashboardPage = () => {
  const organization = useAppSelector(selectOrganization);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDashboardStats(12));
    dispatch(fetchDashboardStats(6));
    dispatch(fetchDashboardStats(3));
  }, []);

  return (
    <BasicLayout className={''} pageName={'Dashboard'}>
      <div className={'grid grid-cols-2 gap-4'}>
        <ShareholderEngagementSummarySection />
        <ShareholderAlerts />
      </div>
      <div className={'grid grid-cols-1 gap-4 my-4'}>
        <TopicAnalysisSummary />
      </div>
      <div className={'grid grid-cols-2 gap-4'}>
        <KeyActionSteps futureMonths={3} title={'Next Steps'} />
        <ActionItemsSection />
      </div>
      <RequiredDatesModal
        preventClose={true}
        open={!organization?.annualMeetingDate || !organization.proxyFilingDate}
        onClose={() => null}
      />
    </BasicLayout>
  );
};
