import { FormInput } from '../../../components/basic/FormInput.tsx';
import { Button, Checkbox, Link } from 'react-daisyui';
import { signUp } from 'aws-amplify/auth';
import { useState } from 'react';
import { parseException } from '../../../utils/utils.ts';
import { LANDING_PAGE_URL } from '../../../utils/constants.ts';

interface Props {
  setLogin: (email?: string) => void;
  setConfirmationState: (email: string, password: string) => void;
  email: string;
}

export const SignUpForm = ({ setLogin, setConfirmationState, email }: Props) => {
  const [form, setForm] = useState({
    email: email || '',
    password: '',
    passwordConfirmation: '',
    disclosure: false,
    sharingDisclosure: false,
  });
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSignUp = async (e) => {
    e.preventDefault();

    if (form.password !== form.passwordConfirmation || passwordError) {
      return;
    }

    if (error) {
      setError('');
    }

    try {
      setLoading(true);
      const { isSignUpComplete, nextStep } = await signUp({
        username: form.email,
        password: form.password,
        options: {
          userAttributes: {
            email: form.email,
          },
          // optional
          autoSignIn: true,
        },
      });

      if (!isSignUpComplete) {
        if (nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
          setConfirmationState(form.email, form.password);
        }
      }
    } catch (e) {
      const error = parseException(
        e,
        'Failed to sign up. If the issue persists, please contact us.',
      );
      if (error === 'ResourceNotFoundException') {
        setError('Failed to sign up. If the issue persists, please contact us.');
      } else {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    if (passwordError) {
      setPasswordError('');
    }

    let errorMessage: string = '';

    if (password.length < 8) {
      errorMessage = 'Password must contain at least 8 characters.';
    }

    if (!/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/.test(password)) {
      errorMessage = 'Password must contain at least 1 special character.';
    }

    if (!/[0-9]/.test(password)) {
      errorMessage = 'Password must contain at least 1 number.';
    }

    if (!/[A-Z]/.test(password)) {
      errorMessage = 'Password must contain at least 1 uppercase letter.';
    }

    if (!/[a-z]/.test(password)) {
      errorMessage = 'Password must contain at least 1 lowercase letter.';
    }

    setPasswordError(password ? errorMessage : '');
    setForm({ ...form, password: password });
  };

  return (
    <>
      <h1 className={'text-3xl'}>Create Account</h1>

      <form className={'w-full'} onSubmit={onSignUp}>
        <FormInput
          fullWidth={true}
          label={'Email'}
          type={'email'}
          required={true}
          placeholder={'Enter your email'}
          autoComplete={'email'}
          value={form.email}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
        />
        <FormInput
          fullWidth={true}
          label={'Password'}
          type={'password'}
          required={true}
          autoComplete={'current-password'}
          value={form.password}
          onChange={handlePasswordChange}
          hint={passwordError}
          error={!!passwordError}
        />
        <FormInput
          fullWidth={true}
          label={'Confirm Password'}
          type={'password'}
          required={true}
          autoComplete={'off'}
          value={form.passwordConfirmation}
          onChange={(e) => setForm({ ...form, passwordConfirmation: e.target.value })}
          hint={
            form.passwordConfirmation && form.password !== form.passwordConfirmation
              ? 'Passwords do not match'
              : ''
          }
          error={!!form.passwordConfirmation && form.password !== form.passwordConfirmation}
        />

        <div className={'flex flex-col gap-4 my-4'}>
          <div className={'flex mt-2'}>
            <Checkbox
              id={'disclosure'}
              className={'mt-0.5 mr-2'}
              size={'xs'}
              required={true}
              checked={form.disclosure}
              onChange={(e) => setForm({ ...form, disclosure: e.target.checked })}
            />
            <label className={'text-xs'} htmlFor={'disclosure'}>
              I agree to the{' '}
              <a
                target={'_blank'}
                rel="noreferrer"
                href={`${LANDING_PAGE_URL}/legal/terms`}
                className="text-blue-600 underline">
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                target={'_blank'}
                rel="noreferrer"
                href={`${LANDING_PAGE_URL}/legal/privacy`}
                className="text-blue-600 underline">
                Privacy Policy
              </a>
              .
            </label>
          </div>
          <div className={'flex mt-2'}>
            <Checkbox
              id={'sharingDisclosure'}
              className={'mt-0.5 mr-2'}
              size={'xs'}
              required={true}
              checked={form.sharingDisclosure}
              onChange={(e) => setForm({ ...form, sharingDisclosure: e.target.checked })}
            />
            <label className={'text-xs'} htmlFor={'sharingDisclosure'}>
              I agree to the{' '}
              <a
                href={`${LANDING_PAGE_URL}/legal/privacy`}
                target={'_blank'}
                rel="noreferrer"
                className="text-blue-600 underline">
                sharing of my data
              </a>{' '}
              with third-party applications as described in the{' '}
              <a
                target={'_blank'}
                rel="noreferrer"
                href={`${LANDING_PAGE_URL}/legal/privacy`}
                className="text-blue-600 underline">
                Privacy Policy
              </a>
              .
            </label>
          </div>
        </div>

        <Button
          color={'primary'}
          type={'submit'}
          fullWidth={true}
          className={'mt-4 text-white'}
          loading={loading}>
          Continue
        </Button>

        <div className={'mt-10 flex items-center justify-center gap-2'}>
          Already have an account?
          <Link
            color={'primary'}
            onClick={() => setLogin(form.email?.includes('@') ? form.email : '')}>
            Log In
          </Link>
        </div>
        {error && <p className={'text-center text-error mt-10 font-semibold text-sm'}>{error}</p>}
      </form>
    </>
  );
};
