import { FormInput } from '../basic/FormInput.tsx';
import { Button } from 'react-daisyui';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/reduxStore.ts';
import { fetchOrganization, selectOrganization } from '../../redux/slices/organizationSlice.ts';
import { patchOrganization } from '../../services/organization.service.ts';

interface Props {
  onSuccess?: () => void;
}

export const KeyCompanyDatesForm = ({ onSuccess }: Props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ annualMeetingDate: '', proxyFilingDate: '' });
  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectOrganization);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!organization?._id) {
      return;
    }

    try {
      setLoading(true);
      await patchOrganization(organization._id, { ...form } as any);
      await dispatch(fetchOrganization(organization._id));
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const temp = { annualMeetingDate: '', proxyFilingDate: '' };
    if (organization?.annualMeetingDate) {
      temp.annualMeetingDate = organization.annualMeetingDate.substring(0, 10);
    }
    if (organization?.proxyFilingDate) {
      temp.proxyFilingDate = organization.proxyFilingDate.substring(0, 10);
    }

    setForm(temp);
  }, [organization]);

  return (
    <form className={'w-full'} onSubmit={handleSubmit}>
      <FormInput
        fullWidth={true}
        label={'Proxy Filing Date'}
        type={'date'}
        required={true}
        //min={formatDateYYYYMMDD(new Date())}
        value={form.proxyFilingDate}
        onChange={(e) => setForm({ ...form, proxyFilingDate: e.target.value })}
      />
      <FormInput
        fullWidth={true}
        label={'Annual Meeting Date'}
        type={'date'}
        required={true}
        // min={formatDateYYYYMMDD(new Date())}
        value={form.annualMeetingDate}
        onChange={(e) => setForm({ ...form, annualMeetingDate: e.target.value })}
      />

      <Button
        color={'primary'}
        type={'submit'}
        fullWidth={true}
        className={'mt-5 text-white'}
        loading={loading}>
        Submit
      </Button>
    </form>
  );
};
