import { useAppSelector } from '../../../redux/reduxStore.ts';
import { selectMessageHistory } from '../../../redux/slices/copilotSlice.ts';
import dayjs from 'dayjs';
import { ICopilotMessage } from '../../../typescript/ICopilotMessage.ts';
import { useEffect, useState } from 'react';
import { MessageHistoryRow } from './MessageHistoryRow.tsx';

export const MessageHistory = () => {
  const history = useAppSelector(selectMessageHistory);
  const [grouped, setGrouped] = useState<
    Record<string, { items: ICopilotMessage[]; label: string }>
  >({
    today: { items: [], label: 'Today' },
    yesterday: { items: [], label: 'Yesterday' },
    LastWeek: { items: [], label: 'Last 7 Days' },
    LastMonth: { items: [], label: 'Last 30 Days' },
    past: { items: [], label: 'Past' },
  });

  const groupMessages = () => {
    const temp: any = {
      today: { items: [], label: 'Today' },
      yesterday: { items: [], label: 'Yesterday' },
      LastWeek: { items: [], label: 'Last 7 Days' },
      LastMonth: { items: [], label: 'Last 30 Days' },
      past: { items: [], label: 'Past' },
    };

    history.forEach((item) => {
      const updatedAt = dayjs(item.updatedAt);

      if (updatedAt.isToday()) {
        temp.today.items.push(item);
      } else if (updatedAt.isYesterday()) {
        temp.yesterday.items.push(item);
      } else if (updatedAt.isAfter(dayjs().subtract(7, 'days'))) {
        temp.LastWeek.items.push(item);
      } else if (updatedAt.isAfter(dayjs().subtract(1, 'month'))) {
        temp.LastMonth.items.push(item);
      } else {
        temp.past.items.push(item);
      }
    });

    return temp;
  };

  useEffect(() => {
    setGrouped(groupMessages());
  }, [history]);

  return (
    <div className={'w-full'}>
      <h4 className={'text-left font-semibold text-2xl mb-5'}>History</h4>
      {!history.length && <p className={'italic'}>No Questions</p>}

      {Object.keys(grouped).map((key) => {
        const group = grouped[key];
        if (!group.items.length) {
          return null;
        }

        const items = group.items.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );

        return (
          <div key={key}>
            <h4
              className={
                'font-semibold text-xs mt-4 w-full flex items-center gap-2 tracking-wider text-nowrap'
              }>
              <div className={'w-[25px] h-[1px] bg-gray-300'} />
              {group.label}
              <div className={'w-full h-[1px] bg-gray-300'} />
            </h4>

            {items.map((message) => {
              return <MessageHistoryRow message={message} key={message._id} />;
            })}
          </div>
        );
      })}
    </div>
  );
};
