import LOGO from '../../assets/logoIcon.png';
import { LoginForm } from './forms/LoginForm.tsx';
import { useEffect, useState } from 'react';
import { SignUpForm } from './forms/SignUpForm.tsx';
import { ForgotPasswordForm } from './forms/ForgotPasswordForm.tsx';
import { ConfirmationForm } from './forms/ConfirmationForm.tsx';
import { useAppDispatch, useAppSelector } from '../../redux/reduxStore.ts';
import { selectUser } from '../../redux/slices/userSlice.ts';
import { PersonalInformationForm } from './forms/PersonalInformationForm.tsx';
import { NoOrganizations } from './forms/NoOrganizations.tsx';
import { fetchOrganization, selectOrganization } from '../../redux/slices/organizationSlice.ts';
import { CompanyDatesForm } from './forms/CompanyDatesForm.tsx';
import { useNavigate } from 'react-router-dom';
import { resetOrganization } from '../../redux/custom/customActions.ts';

enum AuthState {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  CONFIRM_EMAIL,
  PERSONAL_INFO,
  NO_ORG,
  COMPANY_DATES,
}

export const AuthPage = () => {
  const [state, setState] = useState(AuthState.LOGIN);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const user = useAppSelector(selectUser);
  const organization = useAppSelector(selectOrganization);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.firstName || !user.lastName) {
        setState(AuthState.PERSONAL_INFO);
      } else if (!user.organizations.length) {
        setState(AuthState.NO_ORG);
      } else if (!user.lastAccessedOrganization && user.organizations.length > 1) {
        // Navigate to Select Org Page
        navigate('/select-organization');
      } else {
        // Fetch organization
        dispatch(resetOrganization());
        dispatch(fetchOrganization(user.organizations[0]._id));
      }
    } else {
      setState(AuthState.LOGIN);
    }
  }, [user]);

  useEffect(() => {
    // This checks if a user is onboarded and if their company also needs to be onboarded.
    if (organization && user?.firstName) {
      const userRole = organization.users.find((u) => u.user === user._id)?.role;
      if (!organization.annualMeetingDate || !organization.proxyFilingDate) {
        if (userRole === 'owner') {
          setState(AuthState.COMPANY_DATES);
        } else {
          setState(AuthState.NO_ORG);
        }
      } else {
        navigate('/');
      }
    }
  }, [organization]);

  return (
    <div className={'flex flex-col flex-1 bg-secondary-950 h-[100vh] items-center gap-20'}>
      <div className={'flex gap-2 items-end justify-start w-max mt-10'}>
        <img src={LOGO} alt={'logo'} className={'w-[80px]'} />
        <h1 className={'text-white text-[8rem] font-bold  h-[150px]'}>shrub</h1>
      </div>

      <div
        className={
          'card bg-white h-min min-w-[450px] max-w-[650px] w-1/2 min-h-[400px] p-4 items-center justify-center'
        }>
        <div className={'w-[400px] mx-auto flex flex-col items-center py-10'}>
          {state === AuthState.LOGIN && (
            <LoginForm
              email={email}
              setForgotPassword={(email?: string) => {
                setEmail(email || '');
                setState(AuthState.FORGOT_PASSWORD);
              }}
              setSignUp={(email) => {
                setEmail(email);
                setState(AuthState.SIGNUP);
              }}
              setConfirmationState={(email, password) => {
                setEmail(email);
                setPassword(password);
                setState(AuthState.CONFIRM_EMAIL);
              }}
            />
          )}

          {state === AuthState.SIGNUP && (
            <SignUpForm
              email={email}
              setConfirmationState={(email: string, password: string) => {
                setEmail(email);
                setPassword(password);
                setState(AuthState.CONFIRM_EMAIL);
              }}
              setLogin={(email) => {
                setEmail(email || '');
                setState(AuthState.LOGIN);
              }}
            />
          )}

          {state === AuthState.FORGOT_PASSWORD && (
            <ForgotPasswordForm
              email={email}
              setLogin={(email?: string) => {
                setState(AuthState.LOGIN);
                if (email) {
                  setEmail(email);
                }
              }}
            />
          )}
          {state === AuthState.CONFIRM_EMAIL && (
            <ConfirmationForm email={email} password={password} />
          )}
          {state === AuthState.PERSONAL_INFO && <PersonalInformationForm />}
          {state === AuthState.NO_ORG && <NoOrganizations />}
          {state === AuthState.COMPANY_DATES && <CompanyDatesForm />}
        </div>
      </div>
    </div>
  );
};
