import { UserNavigationLink } from './UserNavigationLink.tsx';
import { Button, Drawer, Indicator } from 'react-daisyui';
import { FaRegBell } from 'react-icons/fa';
import { useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/reduxStore.ts';
import { selectLastRoute } from '../../../redux/slices/applicationSlice.ts';
import ShrubLogoIcon from '../../../assets/logoIcon.png';

type Props = {
  pageName?: string;
  backButton?: string;
  showLogo?: boolean;
  hideCopilotButton?: boolean;
};

export const Navbar = ({ pageName, backButton, showLogo }: Props) => {
  const [openNotifications, setOpenNotifications] = useState(false);
  const navigate = useNavigate();
  const lastRoutePage = useAppSelector(selectLastRoute);

  const handleBackButtonClick = () => {
    if (lastRoutePage) {
      navigate(-1);
    } else if (backButton) {
      navigate(backButton);
    }
  };

  return (
    <>
      <div className={'bg-white w-full flex px-4 py-2 items-center border-b z-20'}>
        {backButton && (
          <Button
            onClick={handleBackButtonClick}
            size={'sm'}
            color={'ghost'}
            startIcon={<BiArrowBack className={'text-3xl'} />}
          />
        )}

        {showLogo && (
          <>
            <img
              style={{ minHeight: 30, height: 30, width: 'auto' }}
              src={ShrubLogoIcon}
              alt="shrub"
            />
            <h2 className={'text-5xl pl-2 font-bold'}>shrub</h2>
          </>
        )}

        <h2 className={'text-3xl font-medium'}>{pageName}</h2>
        <span className={'flex-1'} />
        {/*{!hideCopilotButton && (
          <Tooltip message={'Open Shrub AI'} position={'bottom'}>
            <Button
              onClick={() => navigate('/copilot')}
              startIcon={<SparklesIcon className={'w-6'} />}
              size={'sm'}
              animation={false}
              className={
                'mr-4 h-[40px] bg-gradient-to-br from-blue-500 to-purple-500 text-white hover:brightness-90 active:brightness-95 transition-all'
              }>
              Shrub AI
            </Button>
          </Tooltip>
        )}*/}
        <Indicator>
          <Indicator.Item horizontal={'start'}>
            {/*<Badge size={'sm'} color={'secondary'}>*/}
            {/*  3*/}
            {/*</Badge>*/}
          </Indicator.Item>
          <Button
            animation={false}
            size={'sm'}
            className={'mr-4 h-[40px] w-[40px]'}
            startIcon={<FaRegBell className={'text-[24px]'} />}
            onClick={() => setOpenNotifications(true)}
          />
        </Indicator>

        <UserNavigationLink />
      </div>
      <Drawer
        className={'z-20'}
        open={openNotifications}
        end={true}
        onClickOverlay={() => setOpenNotifications(false)}
        side={
          <div className={'bg-white w-[300px] h-full'}>
            <h1 className={'text-center text-xl font-semibold mt-10'}>Notifications</h1>
            <p className={'text-center mt-10 '}>No notifications at this time</p>
          </div>
        }
      />
    </>
  );
};
