// eslint-disable-next-line import/named
import { Button, ButtonProps } from 'react-daisyui';
import { ChangeEvent, useRef, useState } from 'react';

interface Props extends ButtonProps {
  onfileUpload: (files: FileList, ev?: ChangeEvent<HTMLInputElement>) => Promise<void>;
  fileTypes?: HTMLInputElement['accept'];
  multipleFiles?: boolean;
}

export const FileButton = ({
  onfileUpload,
  fileTypes,
  children,
  multipleFiles = false,
  ...buttonProps
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (ev: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    try {
      if (ev.target.files?.length) {
        await onfileUpload(ev.target.files);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (e) {
      console.error('Failed to load file', e);
    }
    setLoading(false);
  };

  return (
    <Button {...buttonProps} loading={loading} onClick={() => fileInputRef.current?.click()}>
      <input
        multiple={multipleFiles}
        hidden={true}
        ref={fileInputRef}
        accept={fileTypes}
        type={'file'}
        onChange={handleFileUpload}
      />
      {children}
    </Button>
  );
};
