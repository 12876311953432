import { useAppDispatch, useAppSelector } from '../../../redux/reduxStore.ts';
import { selectUser } from '../../../redux/slices/userSlice.ts';
import { Avatar, Dropdown } from 'react-daisyui';
import { selectOrganization } from '../../../redux/slices/organizationSlice.ts';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetOrganization } from '../../../redux/custom/customActions.ts';

export const UserNavigationLink = () => {
  const user = useAppSelector(selectUser);
  const organization = useAppSelector(selectOrganization);

  return (
    <DropdownWrapper>
      <div className="flex items-center gap-2">
        {user?.firstName && user?.lastName ? (
          <Avatar
            color={'neutral'}
            size={'xs'}
            shape={'square'}
            letters={user.firstName[0] + user.lastName[0] || ''}
          />
        ) : null}
        <div>
          <p aria-hidden="true">
            {user?.firstName} {user?.lastName}
          </p>
          <p className={'text-primary-500'}>{organization?.name}</p>
        </div>
      </div>
    </DropdownWrapper>
  );
};

const DropdownWrapper = ({ children }: { children: ReactNode }) => {
  const user = useAppSelector(selectUser)!;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSwitch = () => {
    dispatch(resetOrganization());
    navigate('/select-organization');
  };

  return (
    <Dropdown>
      <Dropdown.Toggle button={false}>
        <div className={'hover:bg-gray-200 rounded-lg p-2 cursor-pointer'}>{children}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {user?.organizations?.length > 1 && (
          <Dropdown.Item onClick={handleSwitch}>Switch Company</Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => navigate('/settings')}>Settings</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
