import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';

import { RootState } from '../reduxStore.ts';
import {
  getCopilotMessages,
  postCopilotMessage,
  regenerateCopilotMessage,
} from '../../services/copilot.service.ts';
import {
  ICopilotMessage,
  ICopilotMessageMeetingSource,
  ICopilotMessageWebSource,
} from '../../typescript/ICopilotMessage.ts';

type InitialState = {
  message?: ICopilotMessage;
  source?: ICopilotMessageMeetingSource | ICopilotMessageWebSource;
  history: ICopilotMessage[];
  initialLoad: boolean;
  editQuestion: boolean; // Edit an existing messages question
  generatingMessage: RequestStates;
  fetchingHistory: RequestStates;
};

const initialState: InitialState = {
  message: undefined,
  source: undefined,
  editQuestion: false,
  history: [],
  initialLoad: false,
  generatingMessage: RequestStates.idle,
  fetchingHistory: RequestStates.idle,
};

export const fetchCopilotMessages = createAsyncThunk(
  'copilotSlice/fetchCopilotMessages',
  async (_: undefined, { rejectWithValue, getState }) => {
    try {
      const { organizationSlice } = getState() as RootState;
      return getCopilotMessages(organizationSlice?.organization?._id as string);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { copilotSlice } = getState() as RootState;
      if (copilotSlice.fetchingHistory === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const generateCopilotMessage = createAsyncThunk(
  'copilotSlice/generateCopilotMessage',
  async ({ messageId, ...payload }: Record<any, any>, { rejectWithValue, getState }) => {
    try {
      const { organizationSlice } = getState() as RootState;

      if (messageId) {
        return regenerateCopilotMessage(organizationSlice!.organization!._id, messageId, payload);
      }

      return postCopilotMessage(organizationSlice!.organization!._id, payload);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { copilotSlice } = getState() as RootState;
      if (copilotSlice.generatingMessage === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const copilotSlice = createSlice({
  name: 'copilotSlice',
  initialState: initialState,
  reducers: {
    updateCurrentCopilotMessage: (state, action) => {
      state.message = action.payload;
      if (state.editQuestion) {
        state.editQuestion = false;
      }
    },
    clearCurrentCopilotMessage: (state) => {
      state.message = undefined;
    },
    removeCoPilotMessage: (state, action) => {
      state.history = state.history.filter((m) => m._id !== action.payload);
    },
    toggleEditCoPilotQuestion: (state) => {
      state.editQuestion = !state.editQuestion;
    },
    setCitationDetails: (state, action) => {
      state.source = action.payload;
    },
    updateCoPilotMessage: (state, action) => {
      state.history = state.history.map((m) => {
        if (m._id === action.payload?._id) {
          return action.payload;
        } else {
          return m;
        }
      });
      if (state.message?._id === action.payload?._id) {
        state.message = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCopilotMessages.fulfilled, (state, action) => {
        state.history = action.payload;
        state.fetchingHistory = RequestStates.idle;
        state.initialLoad = true;
      })
      .addCase(fetchCopilotMessages.pending, (state) => {
        state.fetchingHistory = RequestStates.pending;
      })
      .addCase(fetchCopilotMessages.rejected, (state) => {
        state.fetchingHistory = RequestStates.idle;
      });

    builder
      .addCase(generateCopilotMessage.fulfilled, (state, action) => {
        state.history = [
          ...state.history.filter((m) => m._id !== action.payload._id),
          action.payload,
        ];
        state.generatingMessage = RequestStates.idle;
        state.message = action.payload;
      })
      .addCase(generateCopilotMessage.pending, (state) => {
        state.generatingMessage = RequestStates.pending;
        if (state.editQuestion) {
          state.editQuestion = false;
        }
      })
      .addCase(generateCopilotMessage.rejected, (state) => {
        state.generatingMessage = RequestStates.idle;
      });

    // RESET
    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateCurrentCopilotMessage,
  updateCoPilotMessage,
  clearCurrentCopilotMessage,
  removeCoPilotMessage,
  toggleEditCoPilotQuestion,
  setCitationDetails,
} = copilotSlice.actions;

// Selectors
export const selectCopilotMessage = (state: RootState) => state.copilotSlice.message;
export const selectEditCoPilotQuestion = (state: RootState) => state.copilotSlice.editQuestion;
export const selectGeneratingMessageState = (state: RootState) =>
  state.copilotSlice.generatingMessage === RequestStates.pending;
export const selectMessageHistory = (state: RootState) => state.copilotSlice.history;
export const selectMessageSource = (state: RootState) => state.copilotSlice.source;

export default copilotSlice.reducer;
