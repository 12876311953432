import { Button } from 'react-daisyui';
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';

interface Props {
  onRatingChange: (value: 1 | -1) => void;
  rating?: number;
}

export const MessageRating = ({ onRatingChange, rating }: Props) => {
  return rating ? (
    <div className={'flex gap-2 items-center'}>
      <label>You Rated </label>
      {rating > 0 ? (
        <HandThumbUpIcon className={'w-5 text-success'} />
      ) : (
        <HandThumbDownIcon className={'w-5 text-error'} />
      )}
    </div>
  ) : (
    <div className={'flex gap-2 items-center'}>
      <label>Rate this response: </label>
      <Button
        onClick={() => onRatingChange(1)}
        color={'ghost'}
        size={'xs'}
        shape={'circle'}
        startIcon={<HandThumbUpIcon className={'w-5'} />}
      />
      <Button
        onClick={() => onRatingChange(-1)}
        color={'ghost'}
        size={'xs'}
        shape={'circle'}
        startIcon={<HandThumbDownIcon className={'w-5'} />}
      />
    </div>
  );
};
