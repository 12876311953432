import axios from 'axios';
import { SHRUB_API_URL } from '../utils/constants.ts';
import { ICalendarEvent } from '../typescript/ICalendar.ts';

export const getOrganizationCalendar = async (id: string) => {
  const { data } = await axios.get(`${SHRUB_API_URL}/organizations/${id}/calendar`);
  return data;
};

export const postCalendarEvent = async (orgId: string, payload: Omit<ICalendarEvent, '_id'>) => {
  const { data } = await axios.post(
    `${SHRUB_API_URL}/organizations/${orgId}/calendar/events`,
    payload,
  );
  return data;
};

export const deleteCalendarEvent = async (orgId: string, eventId: string) => {
  const { data } = await axios.delete(
    `${SHRUB_API_URL}/organizations/${orgId}/calendar/events/${eventId}`,
  );
  return data;
};

export const putCalendarEvent = async (
  orgId: string,
  eventId: string,
  payload: Omit<ICalendarEvent, '_id'>,
) => {
  const { data } = await axios.put(
    `${SHRUB_API_URL}/organizations/${orgId}/calendar/events/${eventId}`,
    payload,
  );
  return data;
};
