import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SideDrawerLayout } from './components/layout/SideDrawerLayout/SideDrawerLayout.tsx';
import { ErrorPage } from './pages/ErrorPage.tsx';
import { NotFoundPage } from './pages/NotFoundPage.tsx';
import { AuthPage } from './pages/Auth/AuthPage.tsx';
import useAuthListener from './hooks/useAuthListener.ts';
import { PrivateRoute } from './PrivateRoute.tsx';
import { DashboardPage } from './pages/Dashboard/DashboardPage.tsx';
import { CopilotPage } from './pages/CopilotPage/CopilotPage.tsx';
import { ResourcesPage } from './pages/ResourcesPage/ResourcesPage.tsx';
import OutreachesPage from './pages/OutreachesPage/OutreachesPage.tsx';

// Routes
const SettingsPage = lazy(() => import('./pages/SettingsPage/SettingsPage.tsx'));
const SelectOrganizationPage = lazy(() => import('./pages/SelectOrganizationPage.tsx'));
const Oauth2CallbackPage = lazy(() => import('./pages/Oauth2CallbackPage.tsx'));
const InvestorsPage = lazy(() => import('./pages/InvestorsPage/InvestorsPage.tsx'));
const ViewEngagementPage = lazy(() => import('./pages/ViewEngagementPage/ViewEngagementPage.tsx'));
const UserManagementPage = lazy(() => import('./pages/UserManagementPage/UserManagementPage.tsx'));
const ActionItemsPage = lazy(() => import('./pages/ActionItemsPage/ActionItemsPage.tsx'));
const EngagementsPage = lazy(() => import('./pages/EngagementsPage/EngagementsPage.tsx'));
const EngagementStrategyPage = lazy(
  () => import('./pages/EngagementStrategyPage/EngagementStrategyPage.tsx'),
);
const EngagementAnalyticsPage = lazy(
  () => import('./pages/EngagementAnalyticsPage/EngagementAnalyticsPage.tsx'),
);
const InvestorsProfilePage = lazy(
  () => import('./pages/InvestorsProfilePage/InvestorsProfilePage.tsx'),
);

function App() {
  useAuthListener();

  useEffect(() => {
    // After a new deployment, react may fail to lazy load components. This will refresh the page if there is an error.
    const handlePreloadError = () => {
      window.location.reload(); // Refresh the page
    };

    window.addEventListener('vite:preloadError', handlePreloadError);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('vite:preloadError', handlePreloadError);
    };
  }, []);

  return (
    <Suspense>
      <Routes>
        {/*Nested Routes*/}
        <Route element={<SideDrawerLayout />} errorElement={<ErrorPage />}>
          <Route path={'/'} element={<PrivateRoute element={<DashboardPage />} />} />
          <Route
            path={'/engagement-strategy'}
            element={<PrivateRoute element={<EngagementStrategyPage />} />}
          />
          <Route path={'/engagements'} element={<PrivateRoute element={<EngagementsPage />} />} />
          <Route
            path={'/engagements/:meetingId'}
            element={<PrivateRoute element={<ViewEngagementPage />} />}
          />
          <Route
            path={'/engagement-analytics'}
            element={<PrivateRoute element={<EngagementAnalyticsPage />} />}
          />
          <Route
            path={'/investor-intelligence'}
            element={<PrivateRoute element={<InvestorsPage />} />}
          />
          <Route
            path={'/investor-outreaches'}
            element={<PrivateRoute element={<OutreachesPage />} />}
          />
          <Route
            path={'/investor-intelligence/:id'}
            element={<PrivateRoute element={<InvestorsProfilePage />} />}
          />
          <Route path={'/action-items'} element={<PrivateRoute element={<ActionItemsPage />} />} />
          <Route
            path={'/user-management'}
            element={<PrivateRoute element={<UserManagementPage />} />}
          />
          <Route path={'/resources'} element={<PrivateRoute element={<ResourcesPage />} />} />
          <Route path={'/settings'} element={<PrivateRoute element={<SettingsPage />} />} />
        </Route>

        <Route
          path={'/copilot'}
          element={<PrivateRoute element={<CopilotPage />} />}
          errorElement={<ErrorPage />}
        />

        <Route
          path={'/oauth2callback'}
          element={<PrivateRoute element={<Oauth2CallbackPage />} />}
          errorElement={<ErrorPage />}
        />

        <Route
          path={'/select-organization'}
          errorElement={<ErrorPage />}
          element={<PrivateRoute onlyUser={true} element={<SelectOrganizationPage />} />}
        />
        <Route path={'/login'} element={<AuthPage />} />

        <Route path={'*'} element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
