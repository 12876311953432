import { ENVIRONMENT, LIVE_SESSION_ID } from '../utils/constants.ts';
import ls from '@livesession/sdk';

ls.init(
  LIVE_SESSION_ID || '12345',
  { keystrokes: false },
  {
    devMode: ENVIRONMENT !== 'production',
    scriptURL: 'https://cdn.livesession.io/track.js',
  },
);
ls.newPageView();
