import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetRedux } from '../custom/customActions.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';
import { getUser } from '../../services/user.service.ts';
import { RootState } from '../reduxStore.ts';
import { IUser } from '../../typescript/IUser.ts';

type InitialState = { user: IUser | null; initialLoad: boolean; status: RequestStates };

const initialState: InitialState = {
  user: null,
  initialLoad: false,
  status: RequestStates.idle,
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_: undefined, { rejectWithValue }) => {
    try {
      return getUser();
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { userSlice }: any = getState();
      if (userSlice.status === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = RequestStates.idle;
        state.initialLoad = true;
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = RequestStates.pending;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        if (action.error.message === 'user does not exist') {
          state.initialLoad = true;
        }
        state.status = RequestStates.idle;
      });

    // RESET
    builder.addCase(resetRedux, () => initialState);
  },
});

// Action creators are generated for each case reducer function
//export const {} = userSlice.actions;

// Selectors
export const selectUser = (state: RootState) => state.userSlice.user;
export const selectUserInitialLoad = (state: RootState) => state.userSlice.initialLoad;

export default userSlice.reducer;
