import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { twMerge } from 'tailwind-merge';

interface Props {
  options: ApexOptions;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  width?: string | number;
  height?: string | number;
  noDataMessage?: string;
  showNoDataMessage?: boolean;
  type?:
    | 'line'
    | 'area'
    | 'bar'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'candlestick'
    | 'boxPlot'
    | 'radar'
    | 'polarArea'
    | 'rangeBar'
    | 'rangeArea'
    | 'treemap';
  className?: HTMLDivElement['className'];
}

export const ChartWrapper = ({
  options,
  series,
  width = '100%',
  height = 'auto',
  type,
  showNoDataMessage,
  noDataMessage = 'Awaiting data...',
  className = '',
}: Props) => {
  if (options.chart) {
    options.chart.redrawOnParentResize = true;
  } else {
    options.chart = {
      redrawOnParentResize: true,
    };
  }

  if (!options.states?.active) {
    options.states = {
      active: {
        filter: {
          type: 'none', // Prevents a column visual from being clicked
        },
      },
    };
  }

  return (
    <div className={twMerge('relative', className)}>
      {showNoDataMessage && (
        <div
          className={
            'bg-white p-5 shadow-xl absolute top-1/2 -translate-y-[100%] left-1/2 -translate-x-1/2 z-10 border w-max rounded-xl'
          }>
          <span className={'animate-pulse text-xl w-max'}>{noDataMessage}</span>
        </div>
      )}
      <Chart
        options={options}
        series={series}
        width={width || '100%'}
        height={height || '100%'}
        type={type}
      />
    </div>
  );
};
