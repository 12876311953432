import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';
import { RootState } from '../reduxStore.ts';
import { getOrganizationCalendar } from '../../services/calendar.service.ts';
import { ICalendarEvent } from '../../typescript/ICalendar.ts';
import { getBankHolidays, getSECFilingDates } from '../../services/meta.service.ts';

type InitialState = {
  events: ICalendarEvent[];
  genericDates: ICalendarEvent[];
  fetching: Record<string, RequestStates>;
};

const initialState: InitialState = {
  events: [],
  genericDates: [],
  fetching: {},
};

export const fetchOrganizationCalendar = createAsyncThunk(
  'calendarSlice/fetchCalendar',
  async (_: undefined, { rejectWithValue, getState }) => {
    const { organizationSlice }: any = getState();
    try {
      return getOrganizationCalendar(organizationSlice.organization._id);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { calendarSlice }: any = getState();
      if (calendarSlice.fetching['events'] === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchGenericEvents = createAsyncThunk(
  'calendarSlice/fetchGenericEvents',
  async (_: undefined, { rejectWithValue, getState }) => {
    try {
      const { organizationSlice }: any = getState();
      const dates: ICalendarEvent[] = [];

      const [data, bankHolidays]: [ICalendarEvent[], ICalendarEvent[]] = await Promise.all([
        getSECFilingDates(organizationSlice.organization._id),
        getBankHolidays(),
      ]);

      data.forEach((d) => {
        if (!d.category) {
          d.category = 'SECFiling';
        }
        d.immutable = true;
        d.type = 'Key Date';
        dates.push(d);
      });

      bankHolidays.forEach((d) => {
        d.category = 'holiday';
        d.immutable = true;
        d.type = 'Key Date';
        dates.push(d);
      });

      return dates.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const calendarSlice = createSlice({
  name: 'calendarSlice',
  initialState: initialState,
  reducers: {
    resetOrganizationEvents: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGenericEvents.fulfilled, (state, action) => {
      state.genericDates = action.payload;
    });

    builder
      .addCase(fetchOrganizationCalendar.fulfilled, (state, action) => {
        state.events = action.payload;
        state.fetching['events'] === RequestStates.idle;
      })
      .addCase(fetchOrganizationCalendar.pending, (state) => {
        state.fetching['events'] === RequestStates.pending;
      })
      .addCase(fetchOrganizationCalendar.rejected, (state) => {
        state.fetching['events'] === RequestStates.idle;
      });

    // Global RESET
    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { resetOrganizationEvents } = calendarSlice.actions;

// Selectors
export const selectEvents = (state: RootState) => state.calendarSlice.events;
export const selectGenericDates = (state: RootState) => state.calendarSlice.genericDates;
export const selectCalendarEvents = createSelector(
  [selectEvents, selectGenericDates],
  (events, genericDates) => [...events, ...genericDates],
);

export default calendarSlice.reducer;
