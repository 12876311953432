import { FormInput } from '../../../components/basic/FormInput.tsx';
import { Button, Link } from 'react-daisyui';
import { useState } from 'react';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { parseException } from '../../../utils/utils.ts';
import { useSnackbar } from 'notistack';

interface Props {
  setLogin: (email?: string) => void;
  email?: string;
}

export const ForgotPasswordForm = ({ setLogin, email = '' }: Props) => {
  const [initiatedForgotPassword, setInitiatedForgotPassword] = useState(false);
  const [form, setForm] = useState({ code: '', email: email, password: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (error) {
      setError('');
    }

    try {
      setLoading(true);
      if (initiatedForgotPassword) {
        await confirmResetPassword({
          username: form.email,
          confirmationCode: form.code,
          newPassword: form.password,
        });
        enqueueSnackbar('Updated Password', { variant: 'success' });
        setLogin(form.email);
      } else {
        await resetPassword({ username: form.email });

        setInitiatedForgotPassword(true);
      }
    } catch (e) {
      setError(parseException(e, 'Failed to reset password.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className={'text-3xl'}>Forgot Password</h1>

      <form className={'w-full'} onSubmit={handleSubmit}>
        {!initiatedForgotPassword && (
          <FormInput
            fullWidth={true}
            label={'Email'}
            type={'email'}
            required={true}
            placeholder={'Enter your email'}
            autoComplete={'email'}
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        )}

        {initiatedForgotPassword && (
          <>
            <FormInput
              fullWidth={true}
              label={'New Password'}
              type={'password'}
              required={true}
              placeholder={'New Password'}
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />

            <FormInput
              fullWidth={true}
              label={'Code'}
              type={'text'}
              required={true}
              placeholder={'Code'}
              value={form.code}
              onChange={(e) => setForm({ ...form, code: e.target.value })}
              hint={'Please check your email and spam folder.'}
            />
          </>
        )}

        <Button
          color={'primary'}
          type={'submit'}
          fullWidth={true}
          className={'mt-4 text-white'}
          loading={loading}>
          {initiatedForgotPassword ? 'Update Password' : 'Continue'}
        </Button>

        {error && <p className={'text-sm text-error text-center mt-5'}>{error}</p>}

        {!initiatedForgotPassword && (
          <div className={'mt-10 flex items-center justify-center gap-2'}>
            <Link color={'primary'} onClick={() => setLogin()}>
              Back to log in
            </Link>
          </div>
        )}
      </form>
    </>
  );
};
