import axios from 'axios';
import { SHRUB_API_URL } from '../utils/constants.ts';
import { IMetaTag } from '../typescript/IMetaTag.ts';

export const getMetaTags = async (orgId: string, type?: string) => {
  try {
    const { data } = await axios.get(`${SHRUB_API_URL}/meta/${orgId}/tags?tagType=${type || ''}`);
    return data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
};

export const postMetaTag = async (orgId: string, payload: Omit<IMetaTag, '_id'>) => {
  try {
    const { data } = await axios.post(`${SHRUB_API_URL}/meta/${orgId}/tags`, payload);
    return data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
};

export const deleteMetaTag = async (orgId: string, tagId: string) => {
  try {
    const { data } = await axios.delete(`${SHRUB_API_URL}/meta/${orgId}/tags/${tagId}`);
    return data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
};
