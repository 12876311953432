import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/reduxStore.ts';
import { fetchOutreaches, selectOutreaches } from '../../redux/slices/outreachesSlice.ts';
import { FormInput } from '../../components/basic/FormInput.tsx';
import { Button, Drawer } from 'react-daisyui';
import { FormAutoComplete } from '../../components/basic/FormAutoComplete.tsx';
import { selectInvestors } from '../../redux/slices/investorsSlice.ts';
import { enqueueSnackbar } from 'notistack';
import { parseException } from '../../utils/utils.ts';
import { postOutreaches, putOutreaches } from '../../services/outreaches.service.ts';
import { InputWBadges } from '../../components/basic/InputWBadges.tsx';
import { FormTextarea } from '../../components/basic/FormTextarea.tsx';

interface Props {
  open: boolean;
  onClose: () => void;
  outreachId?: string;
}

const DefaultForm = {
  _id: undefined as string | undefined,
  investorHoldingId: '',
  sentAt: '',
  senders: [] as string[],
  recipients: [] as string[],
  notes: '',
};

export const OutreachDrawer = ({ outreachId, open, onClose }: Props) => {
  const outreaches = useAppSelector(selectOutreaches);
  const [form, setForm] = useState({ ...DefaultForm });
  const [loading, setLoading] = useState(false);
  const investors = useAppSelector(selectInvestors);
  const dispatch = useAppDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      setLoading(true);
      if (form._id) {
        const { _id, ...other } = form;
        await putOutreaches(_id, { ...other });
      } else {
        await postOutreaches({ ...form });
      }
      await dispatch(fetchOutreaches()).unwrap();
      enqueueSnackbar(form._id ? 'Updated outreach' : 'Created outreach', { variant: 'success' });
      onClose();
    } catch (e) {
      enqueueSnackbar(parseException(e, form._id ? 'Failed to update' : 'Failed to create'), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!open) {
      setForm({ ...DefaultForm });
    } else {
      if (outreachId) {
        const outreach = outreaches.find((o) => o._id === outreachId);
        if (outreach) {
          setForm({
            sentAt: outreach.sentAt.substring(0, 10),
            senders: outreach.senders,
            recipients: outreach.recipients,
            _id: outreach._id,
            notes: outreach.notes || '',
            investorHoldingId: outreach.investorId,
          });
        }
      }
    }
  }, [open, outreachId]);

  return (
    <Drawer
      className={'z-20 relative'}
      onClickOverlay={onClose}
      end={true}
      open={open}
      side={
        <div className={'bg-white w-2/5 min-w-[600px] h-[100vh] p-4 flex flex-col'}>
          <h3 className={'text-2xl mb-4'}>{form._id ? 'Update Outreach' : 'New Outreach'}</h3>
          <form
            className={'w-full flex-1 overflow-y-auto pb-4 px-1 scrollbar-hide flex flex-col gap-2'}
            onSubmit={handleSubmit}>
            <FormAutoComplete
              required={true}
              fullWidth={true}
              label={'Investor Name'}
              placeholder={'Enter Investor Name'}
              onChange={handleFormChange}
              value={form.investorHoldingId}
              name={'investorHoldingId'}
              options={investors.map((i) => ({ id: i._id, value: i?.investor?.name || '' }))}
            />

            <FormInput
              label={'Sent On'}
              required={true}
              type={'date'}
              onChange={handleFormChange}
              name={'sentAt'}
              value={form.sentAt}
              fullWidth={true}
            />

            <InputWBadges
              entries={form.senders}
              onEntryChange={(senders) => setForm({ ...form, senders })}
              fullWidth={true}
              label={'Sender Email(s)'}
              placeholder={'Press Enter'}
              required={true}
              type={'email'}
            />

            <InputWBadges
              entries={form.recipients}
              onEntryChange={(recipients) => setForm({ ...form, recipients })}
              fullWidth={true}
              label={'Recipient Email(s)'}
              placeholder={'Press Enter'}
              required={true}
            />

            <FormTextarea
              label={'Notes'}
              value={form.notes}
              onChange={handleFormChange}
              name={'notes'}
              fullWidth={true}
              rows={5}
            />
            <span className={'flex-1'} />
            <div className={'flex gap-4 pt-4 border-t w-full'}>
              <Button onClick={onClose} disabled={loading} type={'button'}>
                Cancel
              </Button>
              <span className={'flex-1'} />
              <Button color={'primary'} type={'submit'} loading={loading} disabled={loading}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      }
    />
  );
};
