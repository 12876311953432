import { IInvestorEngagement } from '../typescript/IOrganization.ts';
import { Sentiment } from '../typescript/enums.ts';
import dayjs from 'dayjs';
import { IOutreaches } from '../typescript/IOutreaches.ts';

export const groupMeetingDatesBy = (
  meetings: IInvestorEngagement[],
  unit: 'days' | 'weeks' | 'months' = 'months',
) => {
  const countMap = new Map<number, number>();

  meetings.forEach((meeting) => {
    let dateKey: number;

    switch (unit) {
      case 'days':
        dateKey = dayjs(meeting.meetingDate).startOf('day').toDate().getTime();
        break;
      case 'weeks':
        dateKey = dayjs(meeting.meetingDate).startOf('isoWeek').toDate().getTime();
        break;
      case 'months':
        dateKey = dayjs(meeting.meetingDate).startOf('month').toDate().getTime();
        break;
      default:
        throw new Error(`Invalid unit: ${unit}`);
    }

    const count = countMap.get(dateKey) || 0;
    countMap.set(dateKey, count + 1);
  });

  // build result array
  const result: {
    count: number;
    label: number;
  }[] = [];

  for (const [label, count] of countMap.entries()) {
    result.push({ count, label });
  }

  return result.sort((a, b) => b.label - a.label);
};

export function countMeetings(
  meets: IInvestorEngagement[],
  unit: 'day' | 'week' | 'month',
  timeline?: '1y' | '6m' | '3m' | '1m',
  defaultStart?: number,
  endDate?: number,
): [{ [key: string]: number }, IInvestorEngagement[]] {
  // Create an object to store the counts
  const counts: { [key: string]: number } = {};
  const filteredMeetings: IInvestorEngagement[] = [];

  // Calculate the start date for counting
  let start: dayjs.Dayjs | undefined = undefined;
  switch (timeline) {
    case '1y':
      start = dayjs().subtract(11, 'month').startOf('month');
      break;
    case '6m':
      start = dayjs().subtract(5, 'months').startOf('month');
      break;
    case '3m':
      start = dayjs().subtract(2, 'months').startOf('month');
      break;
    case '1m':
      start = dayjs().subtract(1, 'months').startOf('month');
      break;
  }

  if (!start) {
    if (defaultStart) {
      start = dayjs(defaultStart).startOf('month');
    } else {
      const sorted = meets.sort(
        (a, b) => dayjs(a.meetingDate).valueOf() - dayjs(b.meetingDate).valueOf(),
      );
      if (sorted?.length) {
        start = dayjs(sorted[0].meetingDate).startOf('month');
      } else {
        start = dayjs().startOf('month');
      }
    }
  }

  let temp = start.clone();
  while (temp.valueOf() < dayjs(endDate).valueOf()) {
    counts[temp.valueOf()] = 0;
    temp = temp.add(1, unit);
  }

  // Group the objects by meetingDate
  for (const meet of meets) {
    const meetDate = dayjs(meet.meetingDate);

    // Only consider meetingDates that occur on or after the start date
    if (meetDate.isSameOrBefore(start)) continue;
    filteredMeetings.push(meet);

    // Floor the meetingDate to the next lower unit to group them together
    const floored = meetDate.startOf(unit);

    // Increment the count for this date
    counts[floored.valueOf()] = (counts[floored.valueOf()] || 0) + 1;
  }

  return [counts, filteredMeetings];
}

export const extractEngagementTopics = (engagement: IInvestorEngagement) => {
  return Array.from(
    new Set(engagement?.analysis?.keyTakeaways?.map((ta) => ta.topic)) || [],
  ) as string[];
};

export const groupTakeawaysByTopic = (engagement: IInvestorEngagement) => {
  const temp = (engagement.analysis?.keyTakeaways || []).reduce(
    (acc, { takeaway, topic }) => {
      if (topic in acc) {
        acc[topic].takeaways.push(takeaway);
      } else {
        acc[topic] = { takeaways: [takeaway], topic };
      }

      return acc;
    },
    {} as { [topic: string]: { takeaways: string[]; topic: string } },
  );

  return Object.values(temp);
};

/**
 * This function analyzes an array of numbers consisting of only 0, 1 and 2.
 * It checks the frequency of these numbers and returns a value based on the following conditions:
 *
 * 1) If all numbers are noConcerns, it returns noConcerns.
 * 2) If there are more highConcern's than lowConcern's, it returns highConcern.
 * 3) If there are at least 3 highConcern's, it returns highConcern.
 * 4) If the number of highConcern's make up 30% or more of the total numbers, it returns highConcern.
 *
 * If none of the above conditions are met, it returns lowConcern.
 *
 * @returns {number} -  Result according to the conditions given above.
 * @param engagement
 * @param returnLabel
 */
export function generateOverallSentiment(
  engagement: IInvestorEngagement,
  returnLabel: true,
): Sentiment;
export function generateOverallSentiment(
  engagement: IInvestorEngagement,
  returnLabel?: false,
): number;
export function generateOverallSentiment(
  engagement: IInvestorEngagement,
  returnLabel = false,
): Sentiment | number {
  const numbers = engagement.analysis?.keyTakeaways.map((kt) => kt.sentiment) || [];

  let noConcerns = 0,
    lowConcerns = 0,
    highConcerns = 0;

  for (let i = 0; i < numbers.length; i++) {
    if (convertSentimentScoreToLabel(numbers[i]) === Sentiment.positive) noConcerns++;
    else if (convertSentimentScoreToLabel(numbers[i]) === Sentiment.neutral) lowConcerns++;
    else if (convertSentimentScoreToLabel(numbers[i]) === Sentiment.negative) highConcerns++;
  }

  if (noConcerns === numbers.length) return returnLabel ? Sentiment.positive : 0;
  if (highConcerns > lowConcerns || highConcerns >= 3 || highConcerns / numbers.length >= 0.3)
    return returnLabel ? Sentiment.negative : 1;

  return returnLabel ? Sentiment.neutral : 0.4;
}

export function convertSentimentScoreToLabel(score: number) {
  if (score < 0.2) {
    return Sentiment.positive;
  } else if (score > 0.6) {
    return Sentiment.negative;
  }
  return Sentiment.neutral;
}

export const getLastInvestorEngagement = (
  engagements: IInvestorEngagement[],
  investorId: string,
) => {
  return engagements
    .filter((engagement) => engagement.investor?._id === investorId)
    .sort((a, b) => new Date(b.meetingDate).getTime() - new Date(a.meetingDate).getTime())?.[0];
};

export const getLastInvestorOutreach = (engagements: IOutreaches[], investorId: string) => {
  return engagements
    .filter((engagement) => engagement.investor?._id === investorId)
    .sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime())?.[0];
};
