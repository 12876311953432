import { ModalContainer } from './ModalContainer.tsx';
import { KeyCompanyDatesForm } from '../Forms/KeyCompanyDatesForm.tsx';

interface Props {
  open: boolean;
  preventClose?: boolean;
  onClose: () => void;
}

export const RequiredDatesModal = ({ open, onClose, preventClose = false }: Props) => {
  return (
    <ModalContainer
      title={'Company Key Dates'}
      hideCloseButton={preventClose}
      onClose={onClose}
      open={open}
      disableBackdrop={preventClose}>
      <KeyCompanyDatesForm onSuccess={onClose} />
    </ModalContainer>
  );
};
