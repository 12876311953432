import { Hub } from 'aws-amplify/utils';
import { getCurrentUser } from 'aws-amplify/auth';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/reduxStore.ts';
import { fetchUser, selectUser, selectUserInitialLoad } from '../redux/slices/userSlice.ts';
import { resetRedux } from '../redux/custom/customActions.ts';
import { postUser } from '../services/user.service.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchMembers,
  fetchOrganization,
  fetchOrganizationActionItems,
  selectOrganization,
} from '../redux/slices/organizationSlice.ts';
import { fetchOrganizationTags } from '../redux/slices/metaSlice.ts';
import { fetchEngagements } from '../redux/slices/engagementsSlice.ts';
import { fetchInvestors } from '../redux/slices/investorsSlice.ts';
import { fetchDashboardStats } from '../redux/slices/applicationSlice.ts';
import { fetchGenericEvents, fetchOrganizationCalendar } from '../redux/slices/calendarSlice.ts';
import * as Sentry from '@sentry/react';
import ls from '@livesession/sdk';

const useAuthListener = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const initialLoad = useAppSelector(selectUserInitialLoad);
  const navigate = useNavigate();
  const organization = useAppSelector(selectOrganization);
  const location = useLocation();

  const manageSignIn = () => {
    dispatch(fetchUser());
  };

  const createUser = async () => {
    try {
      await postUser();
      manageSignIn();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const authUser = await getCurrentUser();
        if (authUser.userId) {
          manageSignIn();
        }
      } catch (e) {
        // Do nothing
        navigate('/login');
      }
    };

    init();

    const listener = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          manageSignIn();
          break;
        case 'signedOut':
          dispatch(resetRedux());
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          break;
        case 'signInWithRedirect':
          break;
        case 'signInWithRedirect_failure':
          break;
        case 'customOAuthState':
          break;
      }
    });

    // Clean up
    return () => listener();
  }, []); // Empty array ensures this runs once upon mount.

  useEffect(() => {
    // If we successfully fetched the user and the user is null - must create a new user.
    if (!user && initialLoad) {
      createUser();
    } else if (user && initialLoad) {
      Sentry.setUser({
        id: user._id,
        email: user.email,
      });
      ls.identify({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    }

    let organizationId = '';

    if (user?.organizations?.length === 1) {
      organizationId = user.organizations[0]._id;
    } else if (
      user?.lastAccessedOrganization &&
      user.organizations.find((o) => o._id === user?.lastAccessedOrganization)
    ) {
      organizationId = user.lastAccessedOrganization;
    }

    if (organizationId) {
      dispatch(fetchOrganization(organizationId));
      navigate(
        location.pathname === '/login'
          ? location?.state?.redirect || '/'
          : location.pathname + location.search || '/',
      );
    } else if (user) {
      navigate('/select-organization');
    }
  }, [user, initialLoad]);

  useEffect(() => {
    if (organization?._id && !location.pathname.includes('oauth2callback')) {
      const init = async () => {
        Sentry.setContext('Organization', { id: organization._id, name: organization.name });
        await Promise.all([
          dispatch(fetchMembers()).unwrap(),
          dispatch(fetchGenericEvents()).unwrap(),
          dispatch(fetchOrganizationCalendar()).unwrap(),
          dispatch(fetchDashboardStats(12)).unwrap(),
          dispatch(fetchOrganizationTags()).unwrap(),
          dispatch(fetchOrganizationActionItems()).unwrap(),
        ]);

        await Promise.all([dispatch(fetchEngagements()).unwrap()]);

        dispatch(fetchInvestors());
      };

      init();
    }
  }, [organization]);
};

export default useAuthListener;
