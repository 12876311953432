import { ReactNode, useEffect, useRef, useState } from 'react';

interface Props {
  children: ReactNode;
  popoverMessage: ReactNode | string;
  defaultPosition?: {
    left?: string;
    top?: string;
    bottom?: string;
    right?: string;
  };
}

export const Popover = ({
  children,
  popoverMessage,
  defaultPosition = { right: '0', top: '100%' },
}: Props) => {
  const [show, setShow] = useState(false);
  const [position] = useState(defaultPosition);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const determinePosition = () => {
    // Auto determine position to ensure the popover is in view port.
  };

  useEffect(() => {
    if (popoverRef.current) {
      determinePosition();
    }

    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverRef.current]);

  return (
    <div className={'relative'} ref={triggerRef} onClick={() => setShow(true)}>
      {children}

      {show && (
        <div
          ref={popoverRef}
          style={position}
          className={
            'card bg-white p-2 text-sm border border-gray-400 absolute z-40 max-w-[400px] flex flex-grow w-max whitespace-pre-wrap flex-wrap shadow-xl'
          }>
          {popoverMessage}
        </div>
      )}
    </div>
  );
};
