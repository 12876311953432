import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice.ts';
import { useDispatch, useSelector, useStore } from 'react-redux';
import organizationSlice from './slices/organizationSlice.ts';
import engagementsSlice from './slices/engagementsSlice.ts';
import calendarSlice from './slices/calendarSlice.ts';
import applicationSlice from './slices/applicationSlice.ts';
import copilotSlice from './slices/copilotSlice.ts';
import metaSlice from './slices/metaSlice.ts';
import shareholderAlertsSlice from './slices/shareholderAlertsSlice.ts';
import investorsSlice from './slices/investorsSlice.ts';
import outreachesSlice from './slices/outreachesSlice.ts';

export const reduxStore = configureStore({
  reducer: {
    userSlice,
    organizationSlice,
    engagementsSlice,
    calendarSlice,
    applicationSlice,
    copilotSlice,
    metaSlice,
    shareholderAlertsSlice,
    investorsSlice,
    outreachesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // set false to disable middleware
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof reduxStore.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<typeof reduxStore>();
