import {
  ICopilotMessageDocumentSource,
  ICopilotMessageMeetingSource,
  ICopilotMessageWebSource,
} from '../../../typescript/ICopilotMessage.ts';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxStore.ts';
import { selectInvestorEngagements } from '../../../redux/slices/engagementsSlice.ts';
import { FC } from 'react';
import { setCitationDetails } from '../../../redux/slices/copilotSlice.ts';
import { DocumentIcon, GlobeAltIcon, UserGroupIcon } from '@heroicons/react/24/outline';

interface Props {
  source: ICopilotMessageMeetingSource | ICopilotMessageWebSource | ICopilotMessageDocumentSource;
}

export const SourceRow: FC<Props> = ({ source }) => {
  const meetings = useAppSelector(selectInvestorEngagements);
  const dispatch = useAppDispatch();

  const formatSource = () => {
    if (source.type === 'meeting') {
      const meeting = meetings.find((m) => m._id === source.meetingId);
      if (!meeting) {
        return source.meetingId;
      }

      return `Meeting with ${meeting.investor.investor?.name} on ${new Date(
        meeting.meetingDate,
      ).toDateString()}`;
    }

    return source.title;
  };

  const getIcon = () => {
    if (source.type === 'website') {
      return GlobeAltIcon;
    } else if (source.type === 'meeting') {
      return UserGroupIcon;
    } else {
      return DocumentIcon;
    }
  };

  const Icon = getIcon();

  return (
    <div className={'flex mb-3 gap-2'}>
      <Icon className={'w-6 text-primary-700'} />
      <label
        className={'link hover:font-bold transition-all'}
        onClick={() => dispatch(setCitationDetails(source))}>
        {formatSource()}
      </label>
    </div>
  );
};
