import { Button, Join } from 'react-daisyui';

interface Props {
  active: string;
  onClick: (arg: string) => void;
  size?: 'lg' | 'md' | 'sm' | 'xs';
}

export const ChartTimeSelection = ({ active, onClick, size = 'sm' }: Props) => {
  return (
    <Join>
      <Button
        disabled={active === '1y'}
        size={size}
        onClick={() => onClick('1y')}
        className={'join-item'}>
        1Y
      </Button>
      <Button
        disabled={active === '6m'}
        size={size}
        onClick={() => onClick('6m')}
        className={'join-item'}>
        6M
      </Button>
      <Button
        disabled={active === '3m'}
        size={size}
        onClick={() => onClick('3m')}
        className={'join-item'}>
        3M
      </Button>
    </Join>
  );
};
