import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';
import { RootState } from '../reduxStore.ts';
import { getInvestorById, getOrganizationInvestors } from '../../services/organization.service.ts';
import * as LZString from 'lz-string';
import { IInvestor } from '../../typescript/IInvestorDetails.ts';

type InitialState = {
  initialLoad: boolean;
  requestStatus: Record<string, RequestStates>;
  investors: IInvestor[];
};

const initialState: InitialState = {
  initialLoad: false,
  requestStatus: {
    organization: RequestStates.idle,
    investors: RequestStates.idle,
    actionItems: RequestStates.idle,
    users: RequestStates.idle,
  },
  investors: localStorage.getItem('INVESTORS')
    ? (JSON.parse(LZString.decompressFromUTF16(localStorage.getItem('INVESTORS') as string)) as [])
    : [],
};

export const fetchInvestors = createAsyncThunk(
  'investors/fetchOrgInvestors',
  async (_: undefined, { rejectWithValue, getState }) => {
    try {
      const { organizationSlice } = getState() as RootState;
      return await getOrganizationInvestors(organizationSlice!.organization!._id);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { investorsSlice }: any = getState();
      if (investorsSlice.requestStatus.investors === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchInvestorById = createAsyncThunk(
  'investors/fetchOrganizationInvestorById',
  async (id: string, { rejectWithValue, getState }) => {
    try {
      const { organizationSlice } = getState() as RootState;
      return getInvestorById(organizationSlice?.organization?._id as string, id);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (id, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { investorsSlice }: any = getState();
      if (investorsSlice.requestStatus?.[id] === RequestStates.pending) {
        return false;
      }
    },
  },
);

const investorsSlice = createSlice({
  name: 'investorsSlice',
  initialState: initialState,
  reducers: {
    resetOrganization: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvestors.fulfilled, (state, action) => {
        // Convert the array into a JSON string and compress it
        const compressed = LZString.compressToUTF16(JSON.stringify(action.payload));
        // Store it in localStorage
        localStorage.setItem('INVESTORS', compressed);
        state.investors = action.payload;

        state.requestStatus.investors = RequestStates.idle;
      })
      .addCase(fetchInvestors.pending, (state) => {
        state.requestStatus.investors = RequestStates.pending;
      })
      .addCase(fetchInvestors.rejected, (state) => {
        state.requestStatus.investors = RequestStates.idle;
      });

    builder
      .addCase(fetchInvestorById.fulfilled, (state, action) => {
        if (action.payload) {
          state.investors = state.investors.map((i) => {
            if (i._id === action.meta.arg) {
              return { ...i, ...action.payload };
            }
            return i;
          });
        }
        state.requestStatus[action.meta.arg] = RequestStates.idle;
      })
      .addCase(fetchInvestorById.pending, (state, action) => {
        state.requestStatus[action.meta.arg] = RequestStates.pending;
      })
      .addCase(fetchInvestorById.rejected, (state, action) => {
        state.requestStatus[action.meta.arg] = RequestStates.idle;
      });

    // Global RESET
    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Selectors
export const selectInvestors = (state: RootState) => state.investorsSlice.investors;

export default investorsSlice.reducer;
