import { ElementType, ReactElement, ReactNode } from 'react';

interface IconProps {
  iconSVG: ElementType;
  background?: string;
  backgroundOpacity?: string;
  children?: ReactNode | ReactElement;
  imageColor?: string;
}

function Icon({
  iconSVG: SvgIcon,
  background = 'bg-white',
  backgroundOpacity = '',
  children,
  imageColor,
}: IconProps) {
  return (
    <div
      className={`${background} ${backgroundOpacity} max-h-[36px] max-w-[36px] p-2 flex justify-center items-center rounded-xl`}>
      <SvgIcon width={24} height={24} color={imageColor} />
      {children}
    </div>
  );
}

export default Icon;
