import { CardSection } from '../../../components/CardSection.tsx';
import dayjs from 'dayjs';
import Icon from '../../../components/basic/Icon.tsx';
import { SiGithubactions } from 'react-icons/si';
import { useAppSelector } from '../../../redux/reduxStore.ts';
import { selectActionItems } from '../../../redux/slices/organizationSlice.ts';
import { Badge } from 'react-daisyui';
import { Link, useNavigate } from 'react-router-dom';
import { CalendarIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { ActionItemStatus } from '../../../typescript/enums.ts';
import { Popover } from '../../../components/Popover.tsx';

export const ActionItemsSection = () => {
  const actionItems = useAppSelector(selectActionItems);
  const navigate = useNavigate();

  const filteredItems = actionItems.filter((item) => {
    return item.dueDate
      ? dayjs(item.dueDate).isSameOrAfter(dayjs(Date.now())) &&
          dayjs(item.dueDate).isSameOrBefore(dayjs(Date.now()).add(1, 'months'))
      : true;
  });

  return (
    <CardSection
      title={'Actions To Consider'}
      className={'h-max'}
      icon={<Icon iconSVG={SiGithubactions} imageColor={'#078b6c'} />}
      titleActionButton={
        <>
          <Popover
            popoverMessage={
              <>
                Action items pulled directly from investor feedback. See the{' '}
                <Link to={'/action-items'} className={'link link-primary w-max contents'}>
                  Action Items
                </Link>{' '}
                tab for more info.
              </>
            }>
            <InformationCircleIcon className={'w-8'} />
          </Popover>
        </>
      }>
      <div className="overflow-x-auto border-t border-gray-200 h-[400px] overflow-auto">
        <table className="table">
          {/* head */}
          <thead style={{ height: '64px' }} className={'sticky top-0 bg-white'}>
            <tr className={'text-[14px] text-[black]'}>
              <th>Action Item</th>
              <th>Status</th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((e, i) => {
              return (
                <tr
                  onClick={() =>
                    navigate(e.meetingId ? `/engagements/${e.meetingId}` : '/action-items', {
                      state: { actionId: e._id },
                    })
                  }
                  key={(e._id || e.title) + i}
                  className={'text-[16px] hover:bg-gray-100 active:bg-gray-50 cursor-pointer'}>
                  <td>{e.title}</td>
                  <td>
                    <Badge
                      color={
                        e.status === ActionItemStatus.in_progress
                          ? 'warning'
                          : e.status === ActionItemStatus.complete
                            ? 'primary'
                            : 'secondary'
                      }
                      className={'whitespace-nowrap'}>
                      {e.status}
                    </Badge>
                  </td>
                  <td>
                    <span className="flex items-center gap-3 w-max">
                      <CalendarIcon className={'w-6'} />
                      <span>{e.dueDate ? dayjs(e.dueDate).format('MM/D/YYYY') : 'Ongoing'}</span>
                    </span>
                  </td>
                </tr>
              );
            })}

            {!filteredItems.length && (
              <tr>
                <td className={'text-lg p-5 mt-5 w-full text-center absolute'}>No Action Items</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </CardSection>
  );
};
