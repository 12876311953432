// eslint-disable-next-line import/named
import { Select, SelectProps } from 'react-daisyui';
import { ReactNode } from 'react';

interface Props extends Omit<SelectProps, 'children'> {
  label?: string;
  hint?: string | ReactNode;
  placeholder?: string;
  fullWidth?: boolean;
  error?: boolean;
  options: ({ id?: string; value: string | number } | string | number)[];
  allowEmpty?: boolean;
}

export const FormSelect = ({
  label,
  hint,
  fullWidth = false,
  error,
  options,
  placeholder,
  required,
  allowEmpty = false,
  ...other
}: Props) => {
  return (
    <div className={`form-control ${fullWidth ? 'w-full' : 'max-w-xs'}`}>
      {label && (
        <label className="label pb-1">
          <span className={`label-text ${required && "after:content-['*'] after:text-red-600"}`}>
            {label}
          </span>
        </label>
      )}
      <Select {...other} required={required}>
        <option value={''} disabled={!allowEmpty}>
          {placeholder}
        </option>
        {options.map((opt) => {
          let id, value;

          if (typeof opt === 'object') {
            id = opt.id;
            value = opt.value;
          } else {
            id = opt;
            value = opt;
          }

          return (
            <option key={id || ''} value={id}>
              {value}
            </option>
          );
        })}
      </Select>
      {hint && (
        <>
          {typeof hint === 'string' ? (
            <label className={`label flex font-medium`}>
              <span className={'flex-1'} />
              <span className={`label-text-alt ${error ? 'text-error' : 'text-gray-400'}`}>
                {hint}
              </span>
            </label>
          ) : (
            <span>{hint}</span>
          )}
        </>
      )}
    </div>
  );
};
