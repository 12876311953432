import { BasicLayout } from '../../components/layout/BasicLayout.tsx';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/reduxStore.ts';
import {
  clearCurrentCopilotMessage,
  fetchCopilotMessages,
  selectMessageSource,
  setCitationDetails,
} from '../../redux/slices/copilotSlice.ts';
import { InputBar } from './components/InputBar.tsx';
import { MessagesContainer } from './components/MessagesContainer.tsx';
import { Button, Drawer } from 'react-daisyui';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { MessageHistory } from './components/MessageHistory.tsx';
import { SourcesDrawer } from './components/SourcesDrawer.tsx';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export const CopilotPage = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const querySource = useAppSelector(selectMessageSource);

  const handleNewChatClick = async () => {
    try {
      dispatch(clearCurrentCopilotMessage());
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCopilotMessages());

    return () => {
      dispatch(clearCurrentCopilotMessage());
      dispatch(setCitationDetails(undefined));
    };
  }, []);

  return (
    <BasicLayout
      backButton={'/'}
      pageName={''}
      showLogo={true}
      containerClassName={'p-0'}
      className={'h-[100vh]'}
      hideCopilotButton={true}>
      <div className={'flex w-full h-full'}>
        <div className={'max-w-[250px] flex-col flex-1 box-border pl-4 pr-2 mr-10'}>
          <Button
            color={'primary'}
            className={'w-full my-4'}
            size={'sm'}
            disabled={loading}
            onClick={handleNewChatClick}
            startIcon={<PencilSquareIcon className={'w-4'} />}
            shape={'circle'}>
            New Question
          </Button>

          <MessageHistory />
        </div>
        <div className={'flex flex-1 flex-grow w-full flex-col basis-0 relative px-5'}>
          <InputBar />
          <MessagesContainer />
        </div>
      </div>

      <Drawer
        className={'z-20'}
        open={!!querySource}
        end={true}
        onClickOverlay={() => dispatch(setCitationDetails(undefined))}
        side={<SourcesDrawer />}
      />
    </BasicLayout>
  );
};
