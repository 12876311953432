import axios from 'axios';
import { SHRUB_API_URL } from '../utils/constants.ts';
import { reduxStore } from '../redux/reduxStore.ts';
import RequestBuilder from './utils/RequestBuilder.ts';

const getUrl = () => {
  const { organizationSlice } = reduxStore.getState();
  return `${SHRUB_API_URL}/organizations/${organizationSlice.organization?._id}/meetings`;
};

export const getInvestorMeetings = async (orgId: string) => {
  const { data } = await axios.get(`${SHRUB_API_URL}/organizations/${orgId}/meetings`);
  return data;
};

export const getDashboardStats = async (months: number) => {
  const { data } = await new RequestBuilder()
    .setMethod('get')
    .setUrl(`${getUrl()}/stats?months=${months}`)
    .execute();

  return data;
};

export const getMeetingById = async (orgId: string, meetingId: string) => {
  const { data } = await axios.get(`${SHRUB_API_URL}/organizations/${orgId}/meetings/${meetingId}`);
  return data;
};

export const getMeetingByInvestorId = async (orgId: string, investorId: string) => {
  const { data } = await axios.get(
    `${SHRUB_API_URL}/organizations/${orgId}/investors/${investorId}/meetings`,
  );
  return data;
};

export const createNewInvestorMeeting = async (payload: Record<any, any>) => {
  const { data } = await axios.post(getUrl(), payload);
  return data;
};

export const putInvestorEngagement = async (engagementId: string, payload: Record<any, any>) => {
  const { data } = await new RequestBuilder()
    .setUrl(`${getUrl()}/${engagementId}`)
    .setMethod('put')
    .setData(payload)
    .execute();

  return data;
};

export const postMeetingAttachment = async (
  orgId: string,
  meetingId: string,
  formData: FormData,
) => {
  const { data } = await axios.post(
    `${SHRUB_API_URL}/organizations/${orgId}/meetings/${meetingId}/attachments`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return data;
};

export const getMeetingAnalytics = async (orgId: string, filters: Record<any, any> = {}) => {
  const { data } = await axios.get(
    `${SHRUB_API_URL}/organizations/${orgId}/meetings/analytics?${new URLSearchParams(filters).toString()}`,
  );
  return data;
};

export const rejectMeetingActionItem = async (
  orgId: string,
  meetingId: string,
  description: string,
) => {
  const { data } = await axios.patch(
    `${SHRUB_API_URL}/organizations/${orgId}/meetings/${meetingId}/action-item`,
    { description },
  );
  return data;
};
