export const shortenFileName = (input: string, length: number = 16) => {
  const limit = length > 16 ? length : 16;

  if (input.length > 16) {
    return (
      input.substring(0, Math.floor(limit / 2)) +
      '...' +
      input.substring(input.length - Math.floor(limit / 2))
    );
  } else {
    return input;
  }
};
