import axios from 'axios';
import { SHRUB_API_URL } from '../utils/constants.ts';
import { IInvestor } from '../typescript/IInvestorDetails.ts';
import { reduxStore } from '../redux/reduxStore.ts';
import RequestBuilder from './utils/RequestBuilder.ts';

const getUrl = () => {
  const { organizationSlice } = reduxStore.getState();
  return `${SHRUB_API_URL}/organizations/${organizationSlice?.organization?._id}`;
};

export const getOrganization = async (id: string) => {
  const { data } = await new RequestBuilder()
    .setUrl(`${SHRUB_API_URL}/organizations/${id}`)
    .setMethod('get')
    .execute();
  return data;
};

export const getOrganizationUsers = async (id: string) => {
  const { data } = await axios.get(`${SHRUB_API_URL}/organizations/${id}/members`);
  return data;
};

export const removeOrganizationUser = async (memberId: string) => {
  const { data } = await axios.delete(`${getUrl()}/members/${memberId}`);
  return data;
};

export const patchOrganization = async (
  id: string,
  body: {
    annualMeetingDate: Date;
    proxyFilingDate: Date;
  },
) => {
  const { data } = await axios.patch(`${SHRUB_API_URL}/organizations/${id}`, body);
  return data;
};

export const getOrganizationInvestors = async (orgId: string) => {
  const { data } = await axios.get(`${SHRUB_API_URL}/organizations/${orgId}/investors`);
  return data as IInvestor[];
};

export const getInvestorById = async (orgId: string, investorId: string) => {
  const { data } = await axios.get(
    `${SHRUB_API_URL}/organizations/${orgId}/investors/${investorId}`,
  );
  return data;
};

export const getOrganizationActionItems = async (id: string, meetingId?: string) => {
  const query = meetingId ? `?meeting=${meetingId}` : '';

  const { data } = await axios.get(`${SHRUB_API_URL}/organizations/${id}/action-items${query}`);
  return data;
};

export const postOrganizationActionItem = async (orgId: string, payload: any) => {
  const { data } = await axios.post(`${SHRUB_API_URL}/organizations/${orgId}/action-items`, {
    ...payload,
  });
  return data;
};

export const postOrganizationReferral = async (orgId: string, payload: any) => {
  const { data } = await axios.post(`${SHRUB_API_URL}/organizations/${orgId}/referrals`, {
    ...payload,
  });
  return data;
};

export const deleteOrganizationReferral = async (referralId: string) => {
  const { data } = await axios.delete(`${getUrl()}/referrals/${referralId}`);
  return data;
};

export const putOrganizationActionItem = async (
  orgId: string,
  actionItemId: string,
  payload: any,
) => {
  const { data } = await axios.put(
    `${SHRUB_API_URL}/organizations/${orgId}/action-items/${actionItemId}`,
    {
      ...payload,
    },
  );
  return data;
};

export const deleteActionItem = async (orgId: string, actionItemId: string) => {
  const { data } = await axios.delete(
    `${SHRUB_API_URL}/organizations/${orgId}/action-items/${actionItemId}`,
  );
  return data;
};

export const getOrganizationResources = async (orgId: string, meetingId?: string) => {
  const query = meetingId ? `?meetingId=${meetingId}` : '';
  const { data } = await axios.get(`${SHRUB_API_URL}/organizations/${orgId}/resources${query}`);
  return data;
};

export const deleteResource = async (orgId: string, key: string) => {
  const { data } = await axios.patch(`${SHRUB_API_URL}/organizations/${orgId}/resources`, {
    delete: true,
    key,
  });
  return data;
};

export const postResource = async (orgId: string, form: FormData) => {
  const { data } = await axios.post(`${SHRUB_API_URL}/organizations/${orgId}/resources`, form);
  return data;
};
