import {
  CalendarDaysIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ClockIcon,
  QueueListIcon,
  HomeIcon,
  PencilSquareIcon,
  PresentationChartBarIcon,
  ShareIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { NavigationLink } from './NavigationLink.tsx';
import { ReactNode, useEffect, useState } from 'react';
import { CiLogout } from 'react-icons/ci';
import { useAppDispatch } from '../../../redux/reduxStore.ts';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { resetRedux } from '../../../redux/custom/customActions.ts';
import ShrubLogo from '../../../assets/LogoWhite.png';
import ShrubLogoIcon from '../../../assets/logoIcon.png';
import { Swap, Tooltip } from 'react-daisyui';

export const SideDrawer = () => {
  const [collapse, setCollapse] = useState(!(localStorage.getItem('SIDEDRAWER_COLLAPSED') === '0'));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut();
    dispatch(resetRedux());
    localStorage.clear();
    navigate('/login');
  };

  const navigation = [
    {
      name: 'Dashboard',
      icon: HomeIcon,
      path: '/',
    },
    {
      name: 'Investor Intelligence',
      icon: ShareIcon,
      path: '/investor-intelligence',
    },
    {
      name: 'Investor Scheduling',
      icon: ClockIcon,
      path: '/investor-outreaches',
    },
    {
      name: 'Investor Engagements',
      icon: PencilSquareIcon,
      path: '/engagements',
    },
    {
      name: 'Engagement Analytics',
      icon: PresentationChartBarIcon,
      path: '/engagement-analytics',
    },
    {
      name: 'Engagement Strategy',
      icon: CalendarDaysIcon,
      path: '/engagement-strategy',
    },
    {
      name: 'Action Items',
      icon: QueueListIcon,
      path: '/action-items',
    },
    /*{
      name: 'Resources',
      icon: BookOpenIcon,
      path: '/resources',
    },*/
    {
      name: 'User Management',
      icon: UserGroupIcon,
      path: '/user-management',
    },
  ];

  useEffect(() => {
    localStorage.setItem('SIDEDRAWER_COLLAPSED', collapse ? '' : '0');
  }, [collapse]);

  return (
    <div className={'flex flex-1 relative'}>
      <button
        onClick={() => setCollapse(!collapse)}
        className={
          'text-secondary-950 bg-white rounded-full w-max h-max p-1 border-2 border-solid border-secondary-950 absolute top-1/2 right-0 translate-x-1/2 -translate-y-1/2 z-20'
        }>
        {collapse ? <ChevronDoubleRightIcon height={18} /> : <ChevronDoubleLeftIcon height={18} />}
      </button>
      <div
        className={`relative flex flex-col gap-y-5 bg-secondary-950 transition-all ${
          collapse ? 'w-[60px] min-w-[60px]' : 'min-w-[250px] w-[250px]'
        }`}>
        <div className="flex items-center justify-between shrink-0 text-white text-3xl gap-4 font-semibold mx-2 my-4">
          <span className={'flex gap-2 items-center'}>
            <Swap
              active={collapse}
              onElement={
                <img
                  style={{ minHeight: 40, height: 40, width: 'auto' }}
                  src={collapse ? ShrubLogoIcon : ShrubLogo}
                  alt="shrub"
                />
              }
              offElement={
                <img
                  style={{ minHeight: 40, height: 40, width: 'auto' }}
                  src={collapse ? ShrubLogoIcon : ShrubLogo}
                  alt="shrub"
                />
              }
            />
          </span>
        </div>
        {navigation.map((item) => {
          return (
            <ConditionalTooltip key={item.path} message={item.name} tooltip={collapse}>
              <NavigationLink
                path={item.path}
                name={!collapse ? item.name : ''}
                count={undefined}
                icon={item.icon}
              />
            </ConditionalTooltip>
          );
        })}
        <span className={'flex-1'} />
        <div
          className={'flex items-center py-5 gap-5 cursor-pointer whitespace-nowrap text-gray-400'}
          onClick={handleLogout}>
          <CiLogout className={'h-6 w-6 shrink-0 box-content px-4'} />
          {!collapse && 'Log Out'}
        </div>
      </div>
    </div>
  );
};

const ConditionalTooltip = ({
  tooltip,
  children,
  message,
}: {
  tooltip: boolean;
  children: ReactNode;
  message;
}) => {
  if (tooltip) {
    return (
      <Tooltip message={message} position={'right'} className={'z-10'}>
        {children}
      </Tooltip>
    );
  } else {
    return children;
  }
};
