import { CardSection } from '../../../components/CardSection.tsx';
import {
  removeShareholderAlert,
  selectShareholderAlerts,
} from '../../../redux/slices/shareholderAlertsSlice.ts';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxStore.ts';
import Icon from '../../../components/basic/Icon.tsx';
import {
  ExclamationCircleIcon,
  InboxStackIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { selectInvestors } from '../../../redux/slices/investorsSlice.ts';
import { Button, Tooltip } from 'react-daisyui';
import { useState } from 'react';
import { Popover } from '../../../components/Popover.tsx';

export const ShareholderAlerts = () => {
  const alerts = useAppSelector(selectShareholderAlerts);
  const investors = useAppSelector(selectInvestors);
  const dispatch = useAppDispatch();
  const [hoverRow, setHoverRow] = useState<string>();

  return (
    <CardSection
      icon={<Icon iconSVG={InboxStackIcon} imageColor={'#1EA8E5'} />}
      title={'Shareholder Alerts'}
      titleActionButton={
        <>
          <Popover
            popoverMessage={
              'Alerts include ownership changes for activist shareholders and/or other large shareholder position changes, new/updated investor proxy voting guidelines and priority next steps for your shareholder engagement program.'
            }>
            <InformationCircleIcon className={'w-8'} />
          </Popover>
        </>
      }>
      <div className="overflow-x-auto border-t border-gray-200 h-[400px] overflow-auto">
        <table className="table">
          {/* head */}
          <thead style={{ height: '64px' }} className={'sticky top-0 bg-white'}>
            <tr className={'text-[14px] text-[black]'}>
              <th></th>
              <th>Investor</th>
              <th>% O/S</th>
              <th>Rank</th>
              <th>Last Engagement</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {alerts
              //.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
              .map((alert) => {
                const investor =
                  investors.find((inv) => inv._id === alert.investorId) || alert.investor;
                if (!investor) {
                  return null;
                }
                return (
                  <tr
                    onMouseEnter={() => setHoverRow(alert._id)}
                    onMouseLeave={() => setHoverRow(undefined)}
                    key={alert._id}
                    className={'text-[14px] hover:bg-gray-100'}>
                    <td>
                      <Tooltip message={alert.alert} position={'right'} className={'text-left'}>
                        <ExclamationCircleIcon
                          className={'w-8'}
                          color={
                            alert.priority === 0 ? 'red' : alert.priority === 1 ? 'orange' : 'gray'
                          }
                        />
                      </Tooltip>
                    </td>
                    <td>{investor?.investor?.name}</td>
                    <td>{investor?.percentOwnership?.toFixed(2)}%</td>
                    <td>{investor.rank}</td>
                    <td className={alert.lastEngagement && 'link link-primary'}>
                      {alert.lastEngagement || '-'}
                    </td>
                    <td className={'flex items-center'}>
                      <Button
                        onClick={() => dispatch(removeShareholderAlert(alert._id))}
                        className={`${hoverRow === alert._id ? '' : 'opacity-0'} ml-2`}
                        size={'xs'}
                        color={'ghost'}
                        shape={'circle'}
                        startIcon={<XMarkIcon className={'w-6'} />}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!alerts.length ? <p className={'m-4 text-left ml-16'}>No Shareholder Alerts</p> : ''}
      </div>
    </CardSection>
  );
};
