import { useLocation, useNavigate } from 'react-router-dom';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectUserInitialLoad } from './redux/slices/userSlice.ts';
import { getCurrentUser } from 'aws-amplify/auth';
import { Loading } from 'react-daisyui';
import {
  selectOrganization,
  selectOrganizationInitialLoad,
} from './redux/slices/organizationSlice.ts';

type Props = {
  element: ReactElement;
  onlyUser?: boolean;
};

export const PrivateRoute = ({ element, onlyUser }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState<true | false | null>(null);
  const user = useSelector(selectUser);
  const organization = useSelector(selectOrganization);
  const organizationInitialLoad = useSelector(selectOrganizationInitialLoad);
  const userInitialLoad = useSelector(selectUserInitialLoad);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await getCurrentUser();
        if (!authenticated && userInitialLoad && user?._id) {
          if (onlyUser) {
            setAuthenticated(true);
          } else if (organizationInitialLoad && organization?._id) {
            setAuthenticated(true);
          }
        }
      } catch (e) {
        setAuthenticated(false);
      }
    };

    checkAuth().then();
  }, [userInitialLoad, user, organization, organizationInitialLoad]);

  useEffect(() => {
    if (authenticated === false) {
      const state = { redirect: location.pathname + location.search };
      navigate('/login', { state });
    }
  }, [authenticated]);

  return authenticated ? (
    element
  ) : (
    <div className={'w-full h-full relative'}>
      <Loading className={'absolute left-1/2 -translate-x-1/2 mx-auto block mt-40 w-[150px]'} />
    </div>
  );
};
