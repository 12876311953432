import { BasicLayout } from '../../components/layout/BasicLayout.tsx';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/reduxStore.ts';
import {
  fetchResources,
  removeResource,
  selectAllResources,
  selectOrganization,
} from '../../redux/slices/organizationSlice.ts';
import { FileButton } from '../../components/basic/FileButton.tsx';
import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
// eslint-disable-next-line import/named
import DataTable, { TableColumn } from 'react-data-table-component';
import { CardSection } from '../../components/CardSection.tsx';
import { IResource } from '../../typescript/IOrganization.ts';
import { deleteResource, postResource } from '../../services/organization.service.ts';
import { enqueueSnackbar } from 'notistack';
// eslint-disable-next-line import/named
import { IconType } from 'react-icons';
import { VscFile, VscFileMedia, VscFilePdf } from 'react-icons/vsc';
import { shortenFileName } from '../../utils/filesUtils.ts';
import dayjs from 'dayjs';
import { Button, Tooltip } from 'react-daisyui';
import { TrashIcon } from '@heroicons/react/24/solid';
import { selectUser } from '../../redux/slices/userSlice.ts';
import { useNavigate } from 'react-router-dom';

export const ResourcesPage = () => {
  const dispatch = useAppDispatch();
  const resources = useAppSelector(selectAllResources);
  const [deleteLoading, setDeleteLoading] = useState<Record<string, boolean>>({});
  const user = useAppSelector(selectUser)!;
  const organization = useAppSelector(selectOrganization)!;
  const [prefix] = useState(`/`);
  const navigate = useNavigate();

  const handleAttachmentClick = (attachment: IResource) => {
    const anchorElement = document.createElement('a');

    // Set href to the url
    anchorElement.href = attachment.downloadUrl;

    // Set target to _blank to open in a new window/tab
    anchorElement.target = '_blank';

    // Append the anchor element to the body (this is necessary for Firefox)
    document.body.appendChild(anchorElement);

    // Trigger click on the anchor element
    anchorElement.click();

    // Clean up - remove the anchor element from the body
    document.body.removeChild(anchorElement);
  };
  const handleDeleteAttachmentClick = async (attachment: IResource) => {
    try {
      setDeleteLoading({ ...deleteLoading, [attachment.key]: true });
      await deleteResource(organization._id, attachment.key);
      dispatch(removeResource(attachment.key));
      enqueueSnackbar(`Removed ${attachment.name}`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Failed to remove attachment', { variant: 'error' });
    } finally {
      setDeleteLoading({ ...deleteLoading, [attachment.key]: false });
    }
  };
  function getIconForContentType(contentType: string) {
    let Icon: IconType = VscFile; // Default icon

    if (contentType.includes('/pdf')) {
      Icon = VscFilePdf; // PDF icon for PDF files
    } else if (contentType.includes('image/')) {
      Icon = VscFileMedia;
    }

    return <Icon size={'1rem'} />;
  }

  const columns: TableColumn<IResource>[] = [
    {
      width: '300px',
      name: 'Name',
      cell: (row) => (
        <span
          className={
            'text-ellipsis w-full whitespace-nowrap overflow-hidden text-left flex items-center justify-start gap-1'
          }>
          {getIconForContentType(row.contentType)}
          {shortenFileName(row.name, 35)}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Uploaded By',
      sortable: true,
      selector: (row) => row.uploadedByName,
      format: (row) => (row.uploadedById === user._id ? 'You' : row.uploadedByName),
    },
    {
      name: 'Uploaded Date',
      selector: (row) => dayjs(row.uploadedAt).format('MMM D YYYY'),
      sortable: true,
      sortFunction: (a, b) =>
        new Date(b?.uploadedAt || -1).getTime() - new Date(a?.uploadedAt || -1).getTime(),
    },
    {
      name: 'Actions',
      sortable: false,
      cell: (row) => (
        <>
          <Tooltip message={'Download File'}>
            <Button
              className={'p-0 mr-2'}
              color={'ghost'}
              variant={'link'}
              startIcon={<ArrowDownTrayIcon className={'w-6'} />}
              onClick={() => handleAttachmentClick(row)}
              shape={'circle'}
              size={'sm'}
              disabled={deleteLoading[row.key]}
            />
          </Tooltip>

          {!row.immutable && (
            <Tooltip message={'Delete File'}>
              <Button
                className={'p-0'}
                color={'ghost'}
                variant={'link'}
                loading={deleteLoading[row.key]}
                shape={'circle'}
                size={'sm'}
                startIcon={<TrashIcon className={'w-6 text-error'} />}
                onClick={() => handleDeleteAttachmentClick(row)}
              />
            </Tooltip>
          )}

          {row.meetingId && (
            <Tooltip message={'Go to Meeting Details'}>
              <Button
                className={'p-0'}
                color={'ghost'}
                variant={'link'}
                loading={deleteLoading[row.key]}
                shape={'circle'}
                size={'sm'}
                startIcon={<ArrowTopRightOnSquareIcon className={'w-6 text-primary'} />}
                onClick={() => navigate(`/engagements/${row.meetingId}`)}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  const handleMultiFileSelect = async (files: FileList) => {
    if (!files.length) {
      return;
    }

    const filesDedup: Record<string, File> = Array.from(files).reduce(
      (acc, file) => ({ ...acc, [file.name]: file }),
      {},
    );

    const errorPromises = Object.values(filesDedup).map(async (file) => {
      try {
        const form = new FormData();
        form.append('file', file);
        form.append('prefix', prefix);
        await postResource(organization._id, form);
        return false;
      } catch (e) {
        return true;
      }
    });

    const res = await Promise.all(errorPromises);

    await dispatch(fetchResources());
    if (res.find((r) => r)) {
      enqueueSnackbar('Failed to save 1 or more attachments', { variant: 'error' });
    } else {
      enqueueSnackbar('Attachments Uploaded', { variant: 'success' });
    }
  };

  useEffect(() => {
    dispatch(fetchResources());
  }, []);

  return (
    <BasicLayout pageName={'Resources'}>
      <CardSection
        title={'All Resources'}
        titleActionButton={
          <FileButton
            onfileUpload={handleMultiFileSelect}
            multipleFiles={true}
            color={'primary'}
            startIcon={<PlusIcon className={'w-6'} />}>
            New Attachment
          </FileButton>
        }>
        <DataTable
          columns={columns}
          data={resources}
          noDataComponent={<p className={'my-10'}>No Attachments</p>}
        />
      </CardSection>
    </BasicLayout>
  );
};
