import { Outlet } from 'react-router-dom';
import { SideDrawer } from './SideDrawer.tsx';

export const SideDrawerLayout = () => {
  return (
    <div className={'flex flex-row h-[100vh] overflow-hidden'}>
      <SideDrawer />
      <Outlet />
    </div>
  );
};
