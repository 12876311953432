import { ReactNode, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { Navbar } from './navbar/Navbar.tsx';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../redux/reduxStore.ts';
import { updateLastRoute } from '../../redux/slices/applicationSlice.ts';

interface Props {
  children: ReactNode;
  className?: HTMLDivElement['className'];
  containerClassName?: HTMLDivElement['className'];
  pageName?: string;
  backButton?: string;
  hideCopilotButton?: boolean;
  hideNavbar?: boolean;
  showLogo?: boolean;
}

export const BasicLayout = ({
  children,
  className = '',
  containerClassName = '',
  pageName,
  backButton,
  hideCopilotButton = false,
  showLogo = false,
  hideNavbar,
}: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // This callback will be called whenever the location changes
    return () => {
      if (location.pathname !== window.location.pathname) {
        dispatch(updateLastRoute(location.pathname));
      }
    };
  }, []);

  return (
    <div
      className={twMerge(
        `flex w-full flex-grow flex-col overflow-auto ${hideCopilotButton ? '' : 'pb-[100px]'}`,
        className,
      )}>
      {!hideNavbar && (
        <Navbar
          pageName={pageName}
          backButton={backButton}
          showLogo={showLogo}
          hideCopilotButton={hideCopilotButton}
        />
      )}
      <div className={twMerge('flex flex-1 flex-col flex-grow px-4 py-4', containerClassName)}>
        {children}
      </div>
    </div>
  );
};
