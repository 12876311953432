import { useAppSelector } from '../../../redux/reduxStore.ts';
import { selectMessageSource } from '../../../redux/slices/copilotSlice.ts';
import { selectInvestorEngagements } from '../../../redux/slices/engagementsSlice.ts';
import { Badge, Button, Loading, Tooltip } from 'react-daisyui';
import { ArrowLeftIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import {
  ICopilotMessageDocumentSource,
  ICopilotMessageWebSource,
} from '../../../typescript/ICopilotMessage.ts';
import { Document, Page } from 'react-pdf';
import { useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

export const SourcesDrawer = () => {
  const source = useAppSelector(selectMessageSource);
  const navigate = useNavigate();

  if (!source) {
    return null;
  }

  return (
    <div className={'bg-white w-[800px] h-full p-4 flex flex-col gap-2 overflow-auto'}>
      <Tooltip message={'Go to ...'} className={'absolute top-10 right-4'}>
        <Button
          color={'primary'}
          onClick={() =>
            source.type === 'meeting'
              ? navigate(`/engagements/${source.meetingId}`)
              : window.open(source.url, '_blank')
          }
          startIcon={<ArrowUpRightIcon className={'w-5'} />}
        />
      </Tooltip>
      {source.type === 'meeting' ? (
        <MeetingView meetingId={source.meetingId} />
      ) : source.type === 'website' ? (
        <WebsiteView source={source} />
      ) : (
        <DocumentView source={source} />
      )}
    </div>
  );
};

const MeetingView = ({ meetingId }: { meetingId: string }) => {
  const meetings = useAppSelector(selectInvestorEngagements);
  const meeting = meetings.find((m) => m._id === meetingId);

  if (!meeting) {
    return <p>Unable to display meeting</p>;
  }

  return (
    <>
      <h1 className={'text-3xl mt-5 underline mb-5'}>
        Meeting with <b>{meeting?.investor?.investor?.name}</b>
      </h1>
      <h3 className={'text-xl'}>Meeting Summary</h3>
      <p className={'prose'}>{meeting?.analysis?.summary}</p>
      <br />
      <h3 className={'text-xl'}>External Participants</h3>
      {meeting?.externalParticipants.map((name) => (
        <Badge color={'primary'} size={'lg'} className={'text-xl'} key={name}>
          {name}
        </Badge>
      ))}
      <br />
      <h3 className={'text-xl'}>Internal Participants</h3>
      {meeting?.internalParticipants.map((name) => (
        <Badge color={'primary'} size={'lg'} className={'text-xl'} key={name}>
          {name}
        </Badge>
      ))}
      <br />
      <h3 className={'text-xl'}>Meeting Notes</h3>
      <p className={'prose'}>{meeting.notes}</p>
    </>
  );
};

const WebsiteView = ({ source }: { source: ICopilotMessageWebSource }) => {
  return (
    <>
      <h1 className={'text-3xl mt-5 underline mb-5 pr-10 text-wrap whitespace-pre'}>
        {source.title}
      </h1>
      <div className={'bg-amber-100 p-4 rounded-xl'}>
        <h3 className={'text-xl'}>Citation</h3>
        <p className={'prose'}>{source.citation}</p>
      </div>
      <br />
      <h3 className={'text-xl'}>Page Snippet</h3>
      <p className={'prose'}>...{source.content}...</p>
    </>
  );
};

const DocumentView = ({ source }: { source: ICopilotMessageDocumentSource }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handlePageChange = (forward: boolean) => {
    if (forward && pageNumber < (numPages || 0)) {
      setPageNumber(pageNumber + 1);
    } else if (!forward && pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <>
      <h1 className={'text-3xl mt-5 underline mb-5 pr-10 text-wrap whitespace-pre'}>
        {source.title}
      </h1>

      {source.storageUrl ? (
        <>
          <Document
            file={{ url: source.storageUrl }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Loading size={'lg'} />}>
            <Page
              renderAnnotationLayer={false}
              renderTextLayer={false}
              pageNumber={pageNumber}
              className={'flex justify-center border'}
            />
          </Document>
          <p className={'w-max mx-auto flex gap-4 items-center'}>
            <Button
              shape={'circle'}
              size={'sm'}
              color={'ghost'}
              onClick={() => handlePageChange(false)}
              startIcon={<ArrowLeftIcon className={'w-5'} />}
            />
            Page {pageNumber} of {numPages}
            <Button
              shape={'circle'}
              size={'sm'}
              color={'ghost'}
              onClick={() => handlePageChange(true)}
              startIcon={<ArrowRightIcon className={'w-5'} />}
            />
          </p>
        </>
      ) : (
        <p className={'text-error'}>Error showing document</p>
      )}
    </>
  );
};
