import axios from 'axios';
import { SHRUB_API_URL } from '../utils/constants.ts';
import RequestBuilder from './utils/RequestBuilder.ts';
import { reduxStore } from '../redux/reduxStore.ts';

export const getUser = async () => {
  const { data } = await new RequestBuilder()
    .setUrl(`${SHRUB_API_URL}/user`)
    .setMethod('get')
    .setRetryCount(100)
    .execute();
  return data;
};

export const postUser = async () => {
  const { data } = await axios.post(`${SHRUB_API_URL}/user`);
  return data;
};

export const patchUser = async (body: { firstName: string; lastName: string }) => {
  const { data } = await axios.patch(`${SHRUB_API_URL}/user`, body);
  return data;
};

export const getEmailAuthenticationUrl = async (provider: string) => {
  const { data } = await new RequestBuilder()
    .setUrl(`${SHRUB_API_URL}/user/email-integration?provider=${provider}`)
    .setMethod('get')
    .execute();
  return data;
};

export const exchangeEmailIntegrationCode = async (payload: Record<string, any>) => {
  const { data } = await new RequestBuilder()
    .setUrl(`${SHRUB_API_URL}/user/email-integration`)
    .setMethod('post')
    .setData(payload)
    .setRetryCount(0)
    .execute();
  return data;
};

export const revokeEmailIntegration = async (msRevoke?: boolean) => {
  const { data } = await new RequestBuilder()
    .setUrl(`${SHRUB_API_URL}/user/email-integration${msRevoke ? '?logout=true' : ''}`)
    .setMethod('delete')
    .execute();
  return data;
};

export const sendEmail = async (
  body: string,
  subject: string,
  contacts: { email: string; investorId: string }[],
) => {
  const { organizationSlice } = reduxStore.getState();
  const { data } = await new RequestBuilder()
    .setUrl(`${SHRUB_API_URL}/user/send-email`)
    .setMethod('post')
    .setData(
      contacts.map((c) => {
        return {
          contactEmail: c.email,
          body,
          subject,
          investorId: c.investorId,
          organizationId: organizationSlice.organization!._id,
        };
      }),
    )
    .execute();
  return data;
};
