import { FormInput } from '../../../components/basic/FormInput.tsx';
import { Button, Link } from 'react-daisyui';
import { useState } from 'react';
import { signIn } from 'aws-amplify/auth';
import { parseException } from '../../../utils/utils.ts';

interface Props {
  setForgotPassword: (email?: string) => void;
  setSignUp: (email: string) => void;
  setConfirmationState: (email: string, password: string) => void;
  email: string;
}

export const LoginForm = ({ setForgotPassword, setSignUp, email, setConfirmationState }: Props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ email, password: '' });
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      setError('');
    }
    try {
      setLoading(true);
      const { nextStep } = await signIn({
        username: form.email,
        password: form.password,
      });

      if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        setConfirmationState(form.email, form.password);
      }
    } catch (e: any) {
      const error = parseException(e, 'Failed to login. If the issue persists, please contact us.');
      if (error === 'ResourceNotFoundException') {
        setError('Failed to login. If the issue persists, please contact us.');
      } else if (error === 'NotAuthorizedException') {
        setError('Incorrect email or password.');
      } else {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className={'text-3xl'}>Log In</h1>

      <form className={'w-full'} onSubmit={handleSubmit}>
        <FormInput
          fullWidth={true}
          label={'Email'}
          type={'email'}
          required={true}
          placeholder={'Enter your email'}
          autoComplete={'email'}
          value={form.email}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
        />
        <FormInput
          fullWidth={true}
          label={'Password'}
          type={'password'}
          required={true}
          autoComplete={'current-password'}
          value={form.password}
          onChange={(e) => setForm({ ...form, password: e.target.value })}
          hint={
            <span className={'flex justify-end'}>
              <Link
                className={'text-sm'}
                color={'primary'}
                onClick={() => setForgotPassword(form.email)}>
                Forgot your password?
              </Link>
            </span>
          }
        />

        <Button
          color={'primary'}
          type={'submit'}
          fullWidth={true}
          className={'mt-4 text-white'}
          loading={loading}>
          Continue
        </Button>

        {error && <p className={'text-center text-error mt-10 font-semibold text-sm'}>{error}</p>}

        <div className={'mt-10 flex items-center justify-center gap-2'}>
          Don't have an account?
          <Link
            color={'primary'}
            onClick={() => setSignUp(form.email.includes('@') ? form.email : '')}>
            Sign Up
          </Link>
        </div>
      </form>
    </>
  );
};
