import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';
import { RootState } from '../reduxStore.ts';
import {
  getInvestorMeetings,
  getMeetingAnalytics,
  getMeetingById,
  getMeetingByInvestorId,
} from '../../services/engagements.service.ts';
import { IInvestorEngagement } from '../../typescript/IOrganization.ts';
import * as LZString from 'lz-string';

type InitialState = {
  requestStatus: Record<string, RequestStates>;
  initialLoads: Record<string, boolean>;
  investorMeetings: IInvestorEngagement[];
  multiEngagementAnalytics: {
    result?: any;
    filters?: Record<any, any>;
  };
};

const initialState: InitialState = {
  requestStatus: {},
  initialLoads: {},
  investorMeetings: localStorage.getItem('INVESTOR_MEETINGS')
    ? (JSON.parse(
        LZString.decompressFromUTF16(localStorage.getItem('INVESTOR_MEETINGS') as string),
      ) as [])
    : [],
  multiEngagementAnalytics: {
    result: undefined,
    filters: undefined,
  },
};

export const fetchEngagements = createAsyncThunk(
  'meetings/fetchMeetings',
  async (_: undefined, { rejectWithValue, getState }) => {
    const { organizationSlice }: any = getState();
    try {
      if (!organizationSlice.organization._id) {
        return [];
      }
      return getInvestorMeetings(organizationSlice.organization._id);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { engagementsSlice } = getState() as RootState;
      if (engagementsSlice.requestStatus['all'] === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchEngagementById = createAsyncThunk(
  'meetings/fetchMeetingById',
  async (meetingId: string, { rejectWithValue, getState }) => {
    const { organizationSlice }: any = getState();
    try {
      if (!organizationSlice.organization._id) {
        return null;
      }
      return getMeetingById(organizationSlice.organization._id, meetingId);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (meetingId, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { engagementsSlice } = getState() as RootState;
      if (engagementsSlice.requestStatus[meetingId] === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchEngagementsByInvestorId = createAsyncThunk(
  'meetings/fetchMeetingByInvestorId',
  async (investorId: string, { rejectWithValue, getState }) => {
    const { organizationSlice }: any = getState();
    try {
      if (!organizationSlice.organization._id) {
        return null;
      }
      return getMeetingByInvestorId(organizationSlice.organization._id, investorId);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (investorId, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { engagementsSlice } = getState() as RootState;
      if (engagementsSlice.requestStatus[investorId] === RequestStates.pending) {
        return false;
      }
    },
  },
);

export const fetchMultiEngagementAnalytics = createAsyncThunk(
  'meetings/fetchMultiEngagementAnalytics',
  async (filters: Record<any, any>, { rejectWithValue, getState }) => {
    const { organizationSlice }: any = getState();
    const { highLevelTopic, ...other } = filters;

    const tempFilters = { ...other };
    if (highLevelTopic) {
      tempFilters['topics'] = highLevelTopic;
    }
    try {
      if (!organizationSlice.organization._id) {
        return null;
      }
      return getMeetingAnalytics(organizationSlice.organization._id, filters);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { engagementsSlice } = getState() as RootState;
      if (engagementsSlice.requestStatus[`multiMeetingAnalytics`] === RequestStates.pending) {
        return false;
      }
    },
  },
);

const engagementsSlice = createSlice({
  name: 'engagementsSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEngagements.fulfilled, (state, action) => {
        const compressed = LZString.compressToUTF16(JSON.stringify(action.payload));
        // Store it in localStorage
        localStorage.setItem('INVESTOR_MEETINGS', compressed);
        state.investorMeetings = action.payload;
        state.requestStatus['all'] = RequestStates.idle;
        if (!state.initialLoads['all']) {
          state.initialLoads['all'] = true;
        }
      })
      .addCase(fetchEngagements.pending, (state) => {
        state.requestStatus['all'] = RequestStates.pending;
      })
      .addCase(fetchEngagements.rejected, (state) => {
        state.requestStatus['all'] = RequestStates.idle;
        if (!state.initialLoads['all']) {
          state.initialLoads['all'] = true;
        }
      });

    builder
      .addCase(fetchEngagementById.fulfilled, (state, action) => {
        const meetingId = action.meta.arg;

        if (meetingId) {
          const tempMeetings = state.investorMeetings.filter((m) => m._id !== meetingId);
          if (action.payload) {
            tempMeetings.push(action.payload);
          }
          state.investorMeetings = tempMeetings;
          state.requestStatus[meetingId] = RequestStates.idle;
        }
        if (!state.initialLoads[meetingId]) {
          state.initialLoads[meetingId] = true;
        }
      })
      .addCase(fetchEngagementById.pending, (state, action) => {
        state.requestStatus[action.meta.arg] = RequestStates.pending;
      })
      .addCase(fetchEngagementById.rejected, (state, action) => {
        state.requestStatus[action.meta.arg] = RequestStates.idle;
        if (!state.initialLoads[action.meta.arg]) {
          state.initialLoads[action.meta.arg] = true;
        }
      });

    builder
      .addCase(fetchEngagementsByInvestorId.fulfilled, (state, action) => {
        const investorId = action.meta.arg;

        if (investorId) {
          let tempMeetings = state.investorMeetings.filter((m) => m.investor?._id !== investorId);
          if (action.payload) {
            tempMeetings = [...tempMeetings, ...action.payload];
          }
          state.investorMeetings = tempMeetings;
          state.requestStatus[investorId] = RequestStates.idle;
        }
        if (!state.initialLoads[investorId]) {
          state.initialLoads[investorId] = true;
        }
      })
      .addCase(fetchEngagementsByInvestorId.pending, (state, action) => {
        state.requestStatus[action.meta.arg] = RequestStates.pending;
      })
      .addCase(fetchEngagementsByInvestorId.rejected, (state, action) => {
        state.requestStatus[action.meta.arg] = RequestStates.idle;
        if (!state.initialLoads[action.meta.arg]) {
          state.initialLoads[action.meta.arg] = true;
        }
      });

    builder
      .addCase(fetchMultiEngagementAnalytics.fulfilled, (state, action) => {
        state.requestStatus['multiMeetingAnalytics'] = RequestStates.idle;
        state.multiEngagementAnalytics.result = action.payload;
        state.multiEngagementAnalytics.filters = action.meta.arg;
      })
      .addCase(fetchMultiEngagementAnalytics.pending, (state) => {
        state.requestStatus['multiMeetingAnalytics'] = RequestStates.pending;
      })
      .addCase(fetchMultiEngagementAnalytics.rejected, (state) => {
        state.requestStatus['multiMeetingAnalytics'] = RequestStates.idle;
      });

    // Global RESET
    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Action creators are generated for each case reducer function
// export const {} = engagementsSlice.actions;

// Selectors
export const selectInvestorEngagements = (state: RootState) =>
  state.engagementsSlice.investorMeetings;

export const selectInvestorEngagementsByInvestorId = createSelector(
  [selectInvestorEngagements, (_, investorId) => investorId],
  (meetings, investorId) => meetings.filter((meeting) => meeting.investor._id === investorId),
);

export const selectInvestorEngagement = (id: string) => (state: RootState) =>
  state.engagementsSlice.investorMeetings.find((m) => m._id === id);

export const selectInvestorEngagementsInitialLoad = (id: string) => (state: RootState) =>
  state.engagementsSlice.initialLoads?.[id];

export const selectMultEngagementAnalytics = (state: RootState) =>
  state.engagementsSlice.multiEngagementAnalytics;
export const selectMultEngagementAnalyticsLoading = (state: RootState) =>
  state.engagementsSlice.requestStatus['multiMeetingAnalytics'] === 'pending';

export default engagementsSlice.reducer;
