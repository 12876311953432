import { Amplify, ResourcesConfig } from 'aws-amplify';
import { COGNITO_USER_POOL_CLIENT_ID, COGNITO_USER_POOL_ID } from '../utils/constants.ts';

const config: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolClientId: COGNITO_USER_POOL_CLIENT_ID,
    },
  },
};

Amplify.configure(config);
