import {
  clearCurrentCopilotMessage,
  removeCoPilotMessage,
  selectCopilotMessage,
  updateCurrentCopilotMessage,
} from '../../../redux/slices/copilotSlice.ts';
import { Button, Tooltip } from 'react-daisyui';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { ICopilotMessage } from '../../../typescript/ICopilotMessage.ts';
import { useAppSelector } from '../../../redux/reduxStore.ts';
import { useState } from 'react';
import { patchCopilotMessage } from '../../../services/copilot.service.ts';
import { selectOrganization } from '../../../redux/slices/organizationSlice.ts';

interface Props {
  message: ICopilotMessage;
}

export const MessageHistoryRow = ({ message }: Props) => {
  const dispatch = useDispatch();
  const currentMessage = useAppSelector(selectCopilotMessage);
  const [isHovering, setHovering] = useState(false);
  const organization = useAppSelector(selectOrganization);

  const archiveMessage = async (e) => {
    e.stopPropagation();
    if (message._id === currentMessage?._id) {
      dispatch(clearCurrentCopilotMessage());
    }
    dispatch(removeCoPilotMessage(message._id));
    await patchCopilotMessage(organization!._id, message._id, { archive: true });
  };

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => dispatch(updateCurrentCopilotMessage(message))}
      className={`w-full flex my-2 relative`}
      key={message._id}>
      <p
        className={`w-full text-nowrap overflow-hidden text-ellipsis  text-sm font-semibold ${
          message._id === currentMessage?._id
            ? 'text-primary-600'
            : 'hover:underline active:text-primary-800 cursor-pointer'
        } ${isHovering ? 'pr-5' : ''} `}>
        {message.query}
      </p>
      {isHovering && (
        <Tooltip message={'Archive'} className={'absolute right-0'}>
          <Button
            size={'xs'}
            shape={'circle'}
            color={'ghost'}
            startIcon={<ArchiveBoxIcon className={'w-4'} />}
            onClick={archiveMessage}
          />
        </Tooltip>
      )}
    </div>
  );
};
