import { CardSection } from '../../../components/CardSection.tsx';
import { ApexOptions } from 'apexcharts';
import Icon from '../../../components/basic/Icon.tsx';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '../../../redux/reduxStore.ts';
import { selectInvestorEngagements } from '../../../redux/slices/engagementsSlice.ts';
import { toTitleCase } from '../../../utils/utils.ts';
import { ChartTimeSelection } from '../../../components/ChartTimeSelection.tsx';
import { useState } from 'react';
import { countMeetings, generateOverallSentiment } from '../../../utils/engagementUtils.ts';
import { ChartWrapper } from '../../../ChartWrapper.tsx';
import { Sentiment } from '../../../typescript/enums.ts';
import { PrimaryTopicsBreakdownChart } from '../../EngagementAnalyticsPage/charts/PrimaryTopicsBreakdownChart.tsx';

export const TopicAnalysisSummary = () => {
  const meetings = useAppSelector(selectInvestorEngagements);
  const [timeline, setTimeline] = useState('1y');
  const [, filteredMeetings] = countMeetings(meetings, 'month', timeline as '1y');
  const topicCount = filteredMeetings.reduce(
    (acc, meeting) => {
      meeting.analysis?.keyTakeaways.forEach((ts) => {
        const topic = toTitleCase(ts.topic.toLowerCase(), true);
        if (acc[topic]) {
          acc[topic]++;
        } else {
          acc[topic] = 1;
        }
      });
      return acc;
    },
    {} as { [topic: string]: number },
  );

  const sortedTopicCount = Object.keys(topicCount)
    .map((topic) => {
      return { topic: topic, count: topicCount[topic] };
    })
    .sort((a, b) => b.count - a.count);

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false,
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 8,
        borderRadiusApplication: 'end',
        //hideZeroBarsWhenGrouped: true,
      },
    },
    colors: ['#27C6A6'],
    title: {
      text: 'Top 10 Topics',
    },
    xaxis: {
      title: {
        text: '# Meetings',
        style: {
          fontSize: '15px',
          fontWeight: '400',
        },
      },
      categories: sortedTopicCount.map((t) => t.topic).slice(0, 10),
    },
  };

  const options2: ApexOptions = {
    chart: {
      animations: {
        speed: 700,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: [Sentiment.positive, Sentiment.neutral, Sentiment.negative],
    legend: {
      show: true,
      position: 'bottom',
    },
    colors: ['#c1c6c6', '#5982d8', '#ff4778'],
    title: {
      text: 'Overall Sentiment',
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + ' Meetings';
        },
      },
    },
  };

  const series2 = filteredMeetings.reduce(
    (acc, meeting) => {
      const score = generateOverallSentiment(meeting);
      if (score < 0.2) {
        acc[0]++;
      } else if (score > 0.4) {
        acc[2]++;
      } else {
        acc[1]++;
      }

      return acc;
    },
    [0, 0, 0],
  );

  return (
    <CardSection
      icon={<Icon iconSVG={ChartBarSquareIcon} imageColor={'#078b6c'} />}
      title={'Feedback Topic Analysis'}>
      <div className={'p-2 w-full'}>
        <div className={'flex justify-end'}>
          <ChartTimeSelection onClick={(arg) => setTimeline(arg)} active={timeline} />
        </div>

        <div className={'flex items-start justify-center'}>
          <div className={'flex-1'}>
            <PrimaryTopicsBreakdownChart
              engagements={filteredMeetings}
              className={'border-none'}
              legendPosition={'bottom'}
            />
          </div>
          <div className={'flex-1'}>
            <ChartWrapper
              options={options}
              series={[
                {
                  name: 'Meetings',
                  data: sortedTopicCount.map((t) => t.count).slice(0, 10),
                },
              ]}
              type="bar"
              height={380}
              showNoDataMessage={!sortedTopicCount.length}
            />
          </div>
          <div className={'flex-1'}>
            <ChartWrapper
              options={options2}
              showNoDataMessage={!sortedTopicCount.length}
              series={series2}
              type="donut"
              height={380}
            />
          </div>
        </div>
      </div>
    </CardSection>
  );
};
