import { filterClassNames } from '../../../utils/utils.ts';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  name: string;
  path: string;
  icon: any;
  count?: number;
}

export const NavigationLink = ({ name, icon, path, count }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Icon = icon;
  return (
    <div
      onClick={() => navigate(path)}
      className={filterClassNames(
        path === location.pathname
          ? 'bg-gray-800 text-white'
          : 'text-gray-400 hover:text-white hover:bg-gray-800',
        'group flex gap-x-3 rounded-md py-2 text-sm leading-6 font-semibold cursor-pointer whitespace-nowrap',
      )}>
      <Icon className="h-6 w-6 shrink-0 box-content px-4" aria-hidden="true" />
      {name}
      {count ? (
        <span
          className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-gray-900 px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-white ring-1 ring-inset ring-gray-700"
          aria-hidden="true">
          {count}
        </span>
      ) : null}
    </div>
  );
};
