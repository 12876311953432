export enum MeetingTypes {
  IN_PERSON = 'In Person',
  PHONE_CALL = 'Phone Call',
  VIDEO_CHAT = 'Video Call',
  EMAIL = 'Email',
}

export enum MemberRoles {
  member = 'member',
  admin = 'admin',
  owner = 'owner',
}

export enum ActionItemStatus {
  not_started = 'Not Started',
  in_progress = 'In Progress',
  complete = 'Complete',
}

export enum InvestorActivismLevel {
  LowRisk = 'Low Risk',
  MedRisk = 'Medium Risk',
  HighRisk = 'High Risk',
}

export enum ActionItemPriority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum Sentiment {
  positive = 'No Concern',
  neutral = 'Low Concern',
  negative = 'High Concern',
}

export enum MetaTagTypes {
  InternalUsers = 'InternalUsers',
  InvestorNames = 'InvestorNames',
}
