import React from 'react';
import './modules/index.ts';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './styles/index.scss';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { CgClose } from 'react-icons/cg';
import { Provider } from 'react-redux';
import { reduxStore } from './redux/reduxStore.ts';
import { BrowserRouter } from 'react-router-dom';
import { ENVIRONMENT } from './utils/constants.ts';
import { Analytics } from '@vercel/analytics/react';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <SnackbarProvider
      autoHideDuration={3000}
      action={(snackbarId) => (
        <button onClick={() => closeSnackbar(snackbarId)}>
          <CgClose />
        </button>
      )}>
      <Provider store={reduxStore}>
        <BrowserRouter future={{ v7_startTransition: true }}>
          <App />
          {ENVIRONMENT !== 'local' && <Analytics />}
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  </React.StrictMode>,
);
