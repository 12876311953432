export function filterClassNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const formatMoney = (amount: string | number | undefined, includeSign = true) => {
  if (amount === undefined) {
    amount = 0;
  }

  let amountFloat = amount;

  if (amount !== 0 && amount !== '0' && !amount) {
    return '';
  }

  if (typeof amount === 'string') {
    amountFloat = parseFloat(amount);
  }

  const total = amountFloat.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return includeSign ? '$' + total : total;
};

export const formatNumberWithCommas = (num: number) => {
  const [integerPart, fractionPart] = num.toString().split('.');
  return `${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${fractionPart ? `.${fractionPart}` : ''}`;
};

function toKebab(string) {
  return string
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-');
}

export function toCamelCase(string) {
  return toKebab(string)
    .split('-')
    .map((word, index) => {
      if (index === 0) return word;
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

export function toTitleCase(string: string, defaultUpperCase = false) {
  return toKebab(string)
    .split('-')
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    })
    .map((word) => {
      if (
        [
          'q&a',
          'esg',
          'lti',
          'llc',
          'llc.',
          'ceo',
          'cto',
          'coo',
          'ltd.',
          'ltd',
          'co',
          'co.',
          'lp',
          'lp.',
          'llp',
          'pllc',
          'pllc.',
          'na',
          'n.a.',
          'ai',
          'n.v.',
          'rllp',
          'rllp.',
          'plc.',
          'plc',
        ].includes(word.toLowerCase())
      ) {
        return defaultUpperCase ? word.toUpperCase() : word.toLowerCase();
      }
      return word;
    })
    .join(' ');
}

export function toSentenceCase(string) {
  const interim = toKebab(string).replace(/-/g, ' ');
  return interim.slice(0, 1).toUpperCase() + interim.slice(1);
}

/*
 * Will help parse the exception. Should be added to when different exceptions have different ways to access it.
 * */
export const parseException = (e: any, defaultMessage = '') => {
  let message = e?.response?.data?.error || e?.name || defaultMessage;

  if (typeof message === 'string' && message.includes('Request ') && message.includes(':')) {
    message = message.split(':')?.[1];
  }

  return message as string;
};

/**
 * Converts a string to a color value.
 *
 * @param {string} str - The string to convert to color.
 * @param opacity
 * @return {string} The color value generated from the string.
 */
export function stringToColor(str: string, opacity: number = 1): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).slice(-2);
  }

  // Add opacity
  let alpha = Math.round(opacity * 255);
  alpha = alpha < 0 ? 0 : alpha > 255 ? 255 : alpha;
  const alphaHex = ('00' + alpha.toString(16)).slice(-2);
  color += alphaHex;
  return color;
}

export function groupBy<T>(array: T[], key: string | number | boolean): Record<string, T[]> {
  if (!Array.isArray(array)) {
    throw new Error('"array" must be an array');
  }
  if (typeof key !== 'string') {
    throw new Error('"key" must be a string');
  }

  const group: any = {};

  for (const currentItem of array) {
    const keyValue = currentItem[key];

    if (keyValue === null || keyValue === undefined || typeof keyValue === 'object') {
      throw new Error('"key" value must be a primitive (number, string, or boolean).');
    }

    (group[keyValue as any] = group[keyValue as any] || []).push(currentItem);
  }

  return group;
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Merges two arrays of objects based on a specified key field and removes duplicates.
 *
 * @param {T[]} arr1 - The first array to merge.
 * @param {T[]} arr2 - The second array to merge.
 * @param {keyof T} idField - The key field to use for merging. Default value is '_id'.
 * @returns {T[]} - The merged array without duplicates.
 */
export function mergeArrays<T extends Record<string, any>>(
  arr1: T[],
  arr2: T[],
  idField: keyof T = '_id' as keyof T,
): T[] {
  const lookup = new Map();

  [...arr1, ...arr2].forEach((item) => {
    lookup.set(item[idField], item);
  });

  return Array.from(lookup.values());
}
