import { BasicLayout } from '../../components/layout/BasicLayout.tsx';
import DataTable, { TableColumn } from 'react-data-table-component';
import { CardSection } from '../../components/CardSection.tsx';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/reduxStore.ts';
import { Button, Input } from 'react-daisyui';
import { CgClose } from 'react-icons/cg';
import useDebounce from '../../hooks/useDebounce.ts';
import { IOutreaches } from '../../typescript/IOutreaches.ts';
import { fetchOutreaches, selectOutreaches } from '../../redux/slices/outreachesSlice.ts';
import { OutreachDrawer } from './OutreachDrawer.tsx';
import { EditableInputCell, EditableSelectCell } from '../../components/EditableCells.tsx';
import { patchOutreaches } from '../../services/outreaches.service.ts';
import dayjs from 'dayjs';

const OutreachesPage = () => {
  const dispatch = useAppDispatch();
  const outreaches = useAppSelector(selectOutreaches);
  const [searchTerm, setSearchTerm] = useState('');
  const [upsertOutreach, setUpsertOutreach] = useState(false);
  const [selectedOutreachId, setSelectedOutreachId] = useState('');
  const debounceValue = useDebounce(searchTerm);
  const columns: TableColumn<IOutreaches>[] = [
    {
      width: '250px',
      name: 'Investor Name',
      sortable: true,
      selector: (row) => {
        return row.investor?.investor?.name || '';
      },
    },
    {
      width: '150px',
      name: 'Ownership (%)',
      selector: (row) => {
        if (!row.investor) {
          return '-';
        }
        return row.investor?.percentOwnership?.toFixed(2) || '-';
      },
      sortable: true,
      sortFunction: (a, b) => {
        return (b.investor?.position || 0) - (a.investor?.position || 0);
      },
    },
    {
      width: '150px',
      name: 'Sent Date',
      selector: (row) => new Date(row.sentAt).toDateString(),
      sortable: true,
      sortFunction: (a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime(),
      id: 'sentAt',
    },
    {
      width: '180px',
      name: 'Sent By',
      selector: (row) => row.senders.join(', '),
    },
    {
      name: 'Sent To',
      width: '250px',
      cell: (row) => (
        <div className={'flex gap-1 flex-wrap py-2 whitespace-pre-wrap'}>
          {row.recipients.join(', ')}
        </div>
      ),
    },
    {
      width: '180px',
      name: 'Responded?',
      cell: (row) => (
        <EditableSelectCell
          rowId={row._id}
          displayValue={row?.outreachResponse?.hasResponded ? 'Yes' : 'No'}
          updateData={(rowId, value) => patchData(rowId, { hasResponded: value })}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          value={row?.outreachResponse?.hasResponded ?? false}
        />
      ),
      style: {
        padding: 0,
      },
    },
    {
      width: '180px',
      name: 'Followed Up?',
      cell: (row) => (
        <EditableSelectCell
          rowId={row._id}
          displayValue={row?.outreachResponse?.hasFollowedUp ? 'Yes' : 'No'}
          updateData={(rowId, value) => patchData(rowId, { hasFollowedUp: value })}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          value={row?.outreachResponse?.hasFollowedUp ?? false}
        />
      ),
      style: {
        padding: 0,
      },
    },
    {
      width: '180px',
      name: 'Scheduled Meeting?',
      cell: (row) => (
        <EditableInputCell
          rowId={row._id}
          displayValue={
            row?.outreachResponse?.setMeetingDate
              ? dayjs(row?.outreachResponse?.setMeetingDate.substring(0, 10)).format('MMM DD YYYY')
              : ''
          }
          value={row?.outreachResponse?.setMeetingDate}
          updateData={(rowId, value) => patchData(rowId, { setMeetingDate: value })}
          inputProps={{ type: 'date' }}
        />
      ),
      style: {
        padding: 0,
      },
    },
  ];

  const filterData = () => {
    if (debounceValue) {
      return outreaches.filter((outreach) => {
        return (
          outreach.email.to.join(', ').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (outreach?.investor?.investor?.name || '')
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
      });
    }

    return outreaches;
  };

  async function patchData(rowId: string, payload: any) {
    await patchOutreaches(rowId, payload);
    await dispatch(fetchOutreaches({ _id: rowId }));
  }

  useEffect(() => {
    dispatch(fetchOutreaches());
  }, []);

  return (
    <BasicLayout className={'overflow-auto'} pageName={'Investor Scheduling'}>
      <div className={'flex mb-5 items-center relative w-max'}>
        <Input
          placeholder={'Search Outreaches'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className={'absolute right-2 disabled:bg-white'}
          shape={'circle'}
          size={'sm'}
          color={'ghost'}
          disabled={!searchTerm}
          onClick={() => setSearchTerm('')}
          startIcon={<CgClose />}
        />
      </div>

      <CardSection
        title={'Outreaches'}
        titleActionButton={
          <div className={'flex gap-2'}>
            <Button color={'primary'} onClick={() => setUpsertOutreach(true)}>
              Add new outreach
            </Button>
          </div>
        }>
        <DataTable
          className={'h-[600px]'}
          highlightOnHover={true}
          pagination={true}
          paginationRowsPerPageOptions={[50, 100, 200]}
          paginationPerPage={50}
          onRowClicked={(row) => {
            setSelectedOutreachId(row._id);
            setUpsertOutreach(true);
          }}
          columns={columns}
          responsive={true}
          defaultSortFieldId={'sentAt'}
          data={filterData()}
          fixedHeader={true}
          persistTableHead={true}
          noDataComponent={<p className={'p-10 text-center'}>No Investor Outreaches</p>}
        />
      </CardSection>
      <OutreachDrawer
        outreachId={selectedOutreachId}
        open={upsertOutreach}
        onClose={() => {
          setUpsertOutreach(false);
          setSelectedOutreachId('');
        }}
      />
    </BasicLayout>
  );
};

export default OutreachesPage;
