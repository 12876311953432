import { KeyCompanyDatesForm } from '../../../components/Forms/KeyCompanyDatesForm.tsx';

export const CompanyDatesForm = () => {
  return (
    <>
      <h1 className={'text-3xl mb-5'}>Company Key Dates</h1>

      <KeyCompanyDatesForm />
    </>
  );
};
