export type GenericObjectType = { [key: string]: any };

export interface TModalInterface {
  open: boolean;
  onClose: () => void;
}

export enum RequestStates {
  pending = 'pending',
  idle = 'idle',
  error = 'error',
}
