import calendarGray from '../../../assets/icons/calendar-gray.svg';
import { Badge, Button } from 'react-daisyui';
import Icon from '../../../components/basic/Icon.tsx';
import { InformationCircleIcon, ListBulletIcon, PlusIcon } from '@heroicons/react/24/outline';
import { CardSection } from '../../../components/CardSection.tsx';
import { useAppSelector } from '../../../redux/reduxStore.ts';
import { selectCalendarEvents } from '../../../redux/slices/calendarSlice.ts';
import dayjs from 'dayjs';
import { ICalendarEvent } from '../../../typescript/ICalendar.ts';
import { useNavigate } from 'react-router-dom';
import { Popover } from '../../../components/Popover.tsx';

interface Props {
  futureMonths?: number;
  title?: string;
  setOpenEventModal?: (arg: ICalendarEvent | true | string) => void;
}

function KeyActionSteps({
  futureMonths = 12,
  title = 'Key Action Steps',
  setOpenEventModal,
}: Props) {
  const calendarEvents = useAppSelector(selectCalendarEvents);
  const today = new Date().toISOString().substring(0, 10);
  const navigate = useNavigate();

  const handleRowClick = (event: ICalendarEvent) => {
    navigate('/engagement-strategy', { state: { eventCategory: event.category }, replace: true });
  };

  const filteredEvents = calendarEvents.filter((date) => {
    if (date.type && date.type !== 'Action') {
      return false;
    }

    return (
      (dayjs(date.start).isBefore(dayjs(today)) && dayjs(date.end).isSameOrAfter(dayjs(today))) ||
      (dayjs(date.start).isSameOrAfter(dayjs(today)) &&
        dayjs(date.start).isSameOrBefore(dayjs(today).add(futureMonths, 'months')))
    );
  });

  return (
    <CardSection
      title={title}
      className={'h-max'}
      icon={<Icon iconSVG={ListBulletIcon} imageColor={'#1EA8E5'} />}
      titleActionButton={
        <>
          {setOpenEventModal && (
            <Button
              className="header-section-white-rounded-button mr-2"
              startIcon={<PlusIcon className="w-6" />}
              onClick={() => setOpenEventModal(true)}>
              Add new action
            </Button>
          )}
          <Popover
            popoverMessage={
              'Recommended action steps corresponding with the calendar dates above.'
            }>
            <InformationCircleIcon className={'w-8'} />
          </Popover>
        </>
      }>
      <div className="overflow-x-auto border-t border-gray-200 h-[400px] overflow-auto">
        <table className="table">
          {/* head */}
          <thead style={{ height: '64px' }} className={'sticky top-0 bg-white'}>
            <tr className={'text-[14px] text-[black]'}>
              <th>Action Item</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredEvents
              .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
              .map((e, i) => {
                return (
                  <tr
                    onClick={() => handleRowClick(e)}
                    key={(e._id || e.title) + i}
                    className={'text-[16px] hover:bg-gray-100 active:bg-gray-50 cursor-pointer'}>
                    <td>{e.title}</td>
                    <td>
                      {dayjs(e.start).isAfter(dayjs(today)) ? (
                        <Badge color="secondary" className={'whitespace-nowrap'}>
                          Upcoming
                        </Badge>
                      ) : null}

                      {dayjs(e.start).isBefore(dayjs(today)) ? (
                        <Badge className={'whitespace-nowrap'} color="warning">
                          In Progress
                        </Badge>
                      ) : null}
                    </td>
                    <td>
                      <span className="flex items-center gap-3 w-max">
                        <img src={calendarGray} alt="calendar icon" />
                        <span>{dayjs(e.start).format('MM/D/YYYY')}</span>
                      </span>
                    </td>
                    <td>
                      <span className="flex items-center gap-3 w-max">
                        <img src={calendarGray} alt="calendar icon" />
                        <span>{dayjs(e.end).format('MM/D/YYYY')}</span>
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!filteredEvents.length ? <p className={'m-4 text-lg'}>No upcoming steps</p> : ''}
      </div>
    </CardSection>
  );
}

export default KeyActionSteps;
