import { ChartWrapper } from '../../../ChartWrapper.tsx';
import { IInvestorEngagement } from '../../../typescript/IOrganization.ts';
import { ApexOptions } from 'apexcharts';
import { MeetingTopicsMap } from '../../../utils/constants.ts';
import { twMerge } from 'tailwind-merge';

interface Props {
  engagements: IInvestorEngagement[];
  className?: HTMLDivElement['className'];
  legendPosition?: 'top' | 'right' | 'left' | 'bottom';
}

export const PrimaryTopicsBreakdownChart = ({
  engagements,
  className,
  legendPosition = 'right',
}: Props) => {
  const topicCount = engagements.reduce(
    (acc, meeting) => {
      meeting.analysis?.keyTakeaways.forEach((ts) => {
        ts.labels.forEach((topic) => {
          if (acc[topic]) {
            acc[topic]++;
          } else {
            acc[topic] = 1;
          }
        });
      });
      return acc;
    },
    {} as { [topic: string]: number },
  );

  const highLevelTopicCount = Object.keys(topicCount).reduce(
    (acc, subTopic) => {
      const highLevelTopic =
        Object.keys(MeetingTopicsMap).find((t) => {
          return MeetingTopicsMap[t]?.includes(subTopic);
        }) || 'Other';

      acc[highLevelTopic] = (acc[highLevelTopic] || 0) + topicCount[subTopic];

      return acc;
    },
    {} as Record<any, any>,
  );

  const sortedTopicCount = Object.keys(highLevelTopicCount)
    .map((topic) => {
      return { topic: topic, count: highLevelTopicCount[topic] };
    })
    .sort((a, b) => b.count - a.count);

  const options: ApexOptions = {
    chart: {
      animations: {
        speed: 700,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: sortedTopicCount.map((t) => t.topic),
    legend: {
      show: false,
      position: legendPosition,
    },
    colors: [
      '#27C6A6',
      '#5982d8',
      '#9747FF',
      '#20B2AA',
      '#ADFF2F',
      '#FFD700',
      '#F08080',
      '#FF8C00',
      '#FA8072',
      '#FF6347',
      '#D2691E',
    ],
    title: {
      text: 'Primary Topic Breakdown',
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + ' Meetings';
        },
      },
    },
  };

  return (
    <ChartWrapper
      className={twMerge('border p-2 rounded-lg', className)}
      options={options}
      series={sortedTopicCount.map((t) => t.count)}
      type="pie"
      height={380}
    />
  );
};
