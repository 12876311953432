// eslint-disable-next-line import/named
import { Button, Input, InputProps } from 'react-daisyui';
import { ReactNode, useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { toTitleCase } from '../../utils/utils.ts';
import { CgClose } from 'react-icons/cg';
import { twMerge } from 'tailwind-merge';

interface Props extends InputProps {
  options: { id: string; value: string | number }[];
  label?: string;
  hint?: string | ReactNode;
  fullWidth?: boolean;
  error?: boolean;
  onChange: (arg: any) => void;
  name: string;
  maxItems?: number;
  maxMenuHeight?: string; // Pixels
  showClear?: boolean;
}

export const FormAutoComplete = ({
  label,
  hint,
  fullWidth = false,
  error,
  options,
  onChange,
  name,
  required,
  maxItems,
  showClear,
  maxMenuHeight,
  value,
  className,
  ...other
}: Props) => {
  const [items, setItems] = useState(options);
  const [selectedItem, setSelectedItem] = useState<{ id: string; value: string | number }>(
    options.find((o) => o.value === value) || { id: '', value: '' },
  );
  const { isOpen, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      onInputValueChange({ inputValue }) {
        setItems(
          options.filter((item) => {
            const itemValue = String(typeof item === 'object' ? item.value : item);
            return !inputValue || itemValue.toLowerCase().includes(inputValue.toLowerCase());
          }),
        );
      },
      items,
      itemToString(item) {
        return String(item?.value);
      },
      selectedItem,
      onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
        setSelectedItem(newSelectedItem || { id: '', value: '' }),
    });

  useEffect(() => {
    if (options.length) {
      setItems(options);
    }
  }, [options.length]);

  useEffect(() => {
    onChange({ target: { name, value: selectedItem?.id } });
  }, [selectedItem]);

  useEffect(() => {
    if (value !== selectedItem?.value) {
      setSelectedItem(options.find((o) => o.id === value) || { id: '', value: '' });
    }
  }, [value]);

  return (
    <div className={`relative form-control w-full ${fullWidth ? '' : 'max-w-xs'}`}>
      {label && (
        <label className="label pb-1" {...getLabelProps()}>
          <span className={`label-text ${required && "after:content-['*'] after:text-red-600"}`}>
            {label}
          </span>
        </label>
      )}
      <div className={'relative w-full'}>
        <div className={'flex items-center'}>
          <Input
            className={twMerge('w-full ', className)}
            {...other}
            {...getInputProps()}
            required={required}
          />
          {showClear && (
            <Button
              className={'absolute right-2 disabled:bg-white'}
              shape={'circle'}
              size={'sm'}
              color={'ghost'}
              disabled={!selectedItem.id}
              onClick={() => setSelectedItem({ id: '', value: '' })}
              startIcon={<CgClose />}
            />
          )}
        </div>

        <ul
          style={maxMenuHeight ? { maxHeight: maxMenuHeight } : {}}
          className={`absolute top-full w-full bg-white mt-1 shadow-md ${
            maxMenuHeight ? `` : 'max-h-80'
          }  overflow-scroll p-0 z-10 rounded-xl ${!isOpen && 'hidden'}`}
          {...getMenuProps()}>
          {!items.length && (
            <p className={'text-sm text-center p-2 border'}>No items matching your search</p>
          )}
          {(maxItems ? items.slice(0, maxItems) : items).map((item, index) => {
            let id, value;

            if (typeof item === 'object') {
              id = item.id;
              value = item.value;
            } else {
              id = item;
              value = item;
            }

            return (
              <li
                className={`${
                  highlightedIndex === index ? 'bg-primary-100' : ''
                } py-2 px-3 shadow-sm flex flex-col text-sm border cursor-pointer`}
                key={id || item}
                {...getItemProps({ item, index })}>
                <span>{toTitleCase(value.toLowerCase())}</span>
              </li>
            );
          })}
        </ul>
      </div>
      {hint && (
        <>
          {typeof hint === 'string' ? (
            <label className={`label flex font-medium p-1`}>
              <span className={'flex-1'} />
              <span className={`label-text-alt ${error ? 'text-error' : 'text-gray-400'}`}>
                {hint}
              </span>
            </label>
          ) : (
            <span>{hint}</span>
          )}
        </>
      )}
    </div>
  );
};
