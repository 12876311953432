import { CardSection } from '../../../components/CardSection.tsx';
import Icon from '../../../components/basic/Icon.tsx';
import { ChartPieIcon } from '@heroicons/react/24/outline';
import { ChartTimeSelection } from '../../../components/ChartTimeSelection.tsx';
import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import { ChartWrapper } from '../../../ChartWrapper.tsx';
import { useAppSelector } from '../../../redux/reduxStore.ts';
import { selectDashboardStats } from '../../../redux/slices/applicationSlice.ts';

export const ShareholderEngagementSummarySection = () => {
  const [timeline, setTimeline] = useState('1y');
  const dashboardStats = useAppSelector(selectDashboardStats);
  const [series, setSeries] = useState([0, 0]);
  const [stats, setStats] = useState<Record<string, any>>({});
  const topHoldersLimit = 20;

  const options: ApexOptions = {
    chart: {
      animations: {
        speed: 700,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: ['Outreached', 'Engaged'],
    legend: {
      show: false,
      position: 'bottom',
    },
    colors: ['#5982d8', '#9747FF'],
    title: {
      text: '',
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + '% O/S';
        },
      },
    },
  };

  useEffect(() => {
    const months = timeline[1] === 'y' ? 12 : parseInt(timeline[0]);
    if (dashboardStats[months]) {
      const stats = dashboardStats[months].engagementOSSummary;

      setStats({
        engaged: stats.engagementOSP,
        outreached: stats.outreachOSP,
        topCount: stats.topInvestors,
        topCountPercent: stats.topInvestorsP,
      });

      setSeries([stats.outreachOSP, stats.engagementOSP]);
    }
  }, [timeline, dashboardStats]);

  return (
    <CardSection
      icon={<Icon iconSVG={ChartPieIcon} imageColor={'#078b6c'} />}
      title={'Shareholder Engagement Summary'}>
      <div className={'p-2 w-full flex flex-col lg:flex-row items-center justify-center h-full'}>
        <div className={'flex-1 h-full flex flex-col items-center justify-center'}>
          <div className={'flex justify-center md:justify-end w-full'}>
            <ChartTimeSelection onClick={(arg) => setTimeline(arg)} active={timeline} />
          </div>
          <div className={'w-full flex min-h-[300px]'}>
            <ChartWrapper
              className={'flex items-center justify-center flex-1 w-full min-w-[300px]'}
              options={options}
              series={series}
              width={'100%'}
              height={'100%'}
              type={'radialBar'}
              showNoDataMessage={!Object.keys(dashboardStats).length}
            />
          </div>
        </div>
        <div className={'border p-4 rounded-md flex flex-col w-max'}>
          <div className={'flex items-center gap-2 flex-wrap'}>
            <span className={'font-normal text-xl'}>Engaged:</span>{' '}
            <span className={'text-xl text-purple-600 font-semibold'}>
              {Math.round(stats.engaged || 0)}% O/S
            </span>
          </div>
          <div className={'flex items-center gap-2 flex-wrap text-lg md:text-xl'}>
            <span className={'font-normal'}>Outreached:</span>{' '}
            <span className={'text-blue-600 font-semibold'}>
              {Math.round(stats.outreached || 0)}% O/S
            </span>
          </div>
          <div className={'items-center text-main-color mt-2 text-xs md:text-sm'}>
            <div className={'max-w-[230px]'}>
              Engaged with
              <b className={'font-bold mx-1'}>{stats.topCount}</b>
              of your Top {topHoldersLimit} Investors representing
              <b className={'font-bold mx-1'}>{stats.topCountPercent?.toFixed(2)}% </b>O/S
            </div>
          </div>
        </div>
      </div>
    </CardSection>
  );
};
