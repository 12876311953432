import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../reduxStore.ts';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';
import { getDashboardStats } from '../../services/engagements.service.ts';

interface IDashboardStats {
  [key: string]: {
    engagementOSSummary: {
      engagementOSP: number;
      outreachOSP: number;
      topInvestors: number;
      topInvestorsP: number;
    };
  };
}

type InitialState = {
  lastRoute: string;
  engagementStrategyInstructionsOpen: boolean;
  selectedCalendarEventCategories: string[];
  investorFilters: Record<string, any>;
  dashboardStats: IDashboardStats;
  fetching: Record<string, RequestStates>;
};

const initialState: InitialState = {
  lastRoute: '',
  engagementStrategyInstructionsOpen: false,
  selectedCalendarEventCategories: [],
  investorFilters: {},
  dashboardStats: {},
  fetching: {},
};

export const fetchDashboardStats = createAsyncThunk(
  'meta/fetchDashboardStats',
  async (months: number, { rejectWithValue }) => {
    try {
      return getDashboardStats(months);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (months, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { metaSlice } = getState() as RootState;
      if (metaSlice.fetching?.[`dashboard-${months}`] === RequestStates.pending) {
        return false;
      }
    },
  },
);

const applicationSlice = createSlice({
  name: 'applicationSlice',
  initialState: initialState,
  reducers: {
    updateLastRoute: (state, action) => {
      state.lastRoute = action.payload;
    },
    toggleEngagementStrategyInstructions: (state) => {
      state.engagementStrategyInstructionsOpen = !state.engagementStrategyInstructionsOpen;
    },
    setSelectedCalendarEventCategories: (state, action) => {
      state.selectedCalendarEventCategories = action.payload;
    },
    updateInvestorFilters: (state, action) => {
      state.investorFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardStats.fulfilled, (state, action) => {
        state.dashboardStats[action.meta.arg] = action.payload;
        state.fetching[`dashboard-${action.meta.arg}`] = RequestStates.idle;
      })
      .addCase(fetchDashboardStats.pending, (state, action) => {
        state.fetching[`dashboard-${action.meta.arg}`] = RequestStates.pending;
      })
      .addCase(fetchDashboardStats.rejected, (state, action) => {
        state.fetching[`dashboard-${action.meta.arg}`] = RequestStates.idle;
      });

    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLastRoute,
  toggleEngagementStrategyInstructions,
  setSelectedCalendarEventCategories,
  updateInvestorFilters,
} = applicationSlice.actions;

// Selectors
export const selectLastRoute = (state: RootState) => state.applicationSlice.lastRoute;
export const selectCalendarEventCategories = (state: RootState) =>
  state.applicationSlice.selectedCalendarEventCategories;
export const selectEngagementStrategyInstructionsOpen = (state: RootState) =>
  state.applicationSlice.engagementStrategyInstructionsOpen;
export const selectInvestorFilters = (state: RootState) => state.applicationSlice.investorFilters;
export const selectDashboardStats = (state: RootState) => state.applicationSlice.dashboardStats;

export default applicationSlice.reducer;
