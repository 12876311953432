export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ConfirmationModalDefaults = {
  open: false,
  onClose: () => {},
  title: '',
  description: '',
};

export const MeetingTopicsMap = {
  'Executive Compensation': [
    'Performance Alignment',
    'Pay Quantum',
    'Pay Equity',
    'Short-Term Incentive Plan',
    'Long-Term Incentive Plan',
    'Salary',
    'Severance',
    'Perquisites',
    'Compensation Peer Group',
    'Director Compensation',
    'Reporting',
  ],
  'Sustainability Program': [
    'Programs and Practices',
    'Goals and Targets',
    'Frameworks & Surveys (CDP, MSCI, Sustainalytics)',
    'Risks and Opportunities',
    'Reporting',
  ],
  'Environmental Matters': [
    'Climate Risk',
    'Climate Targets',
    'Net Zero & GHG Emissions',
    'Natural Capital, Environment & Biodiversity',
    'Water Usage and Pollution',
    'Product Life Cycle',
    'Air Pollution',
    'Reporting',
  ],
  'Human Capital Management & Social Matters': [
    'Workforce Retention & Recruiting',
    'Political Activities & Lobbying',
    'Diversity, Equity & Inclusion',
    'Workforce Diversity',
    'Supply Chain',
    'Labor Practices',
    'Conflict Minerals',
    'Political & Lobbying',
    'Community Outreach & Development',
    'Volunteering & Philanthropy',
    'Anti-ESG',
    'Reproduction Rights and Abortion',
    'Reporting',
  ],
  'Cybersecurity & Technology': ['Artificial Intelligence', 'Cybersecurity', 'Reporting'],
  Governance: ['Shareholder Rights', 'Shareholder Proposals', 'Reporting'],
  'Board of Directors': [
    'Board Composition & Refreshment',
    'Director Skills',
    'Director Tenure',
    'Director Independence',
    'Director Age',
    'Board Oversight',
    'Committee Structure',
    'Board Leadership Structure',
    'Board Diversity',
    'Reporting',
  ],
  Management: ['Succession', 'Structure and Oversight', 'Leadership Diversity', 'Reporting'],
  'Shareholder Engagement': [
    'Shareholder Engagement',
    'Proxy Advisor Engagement',
    'Shareholder Activism & Agitation',
    'Investor Relations Engagement & Targeting',
    'Investor Day',
    'Investor Conferences',
    'Quarterly Earnings Calls',
    'Reporting',
  ],
  Business: [
    'Business Strategy & Operations',
    'Financial & Stock Performance',
    'Capital Allocation / M&A',
    'Business Risk Management',
    'Reporting',
  ],
};

export const MeetingTopics = Object.keys(MeetingTopicsMap);

export const CalendarEventCategories = [
  {
    name: 'Send Meeting Requests',
    category: 'sendMeetingRequest',
    userEnabled: true,
  },
  {
    name: 'Proxy Drafting',
    category: 'ProxyDraft',
    userEnabled: true,
  },
  {
    name: 'Nominee Window Open/Close',
    category: 'NomineeWindow',
    userEnabled: true,
  },
  {
    name: 'Shareholder Proposal Deadline',
    category: 'SPDeadline',
    userEnabled: true,
  },
  {
    name: 'Investor Engagement',
    category: 'investorEngagement',
    userEnabled: true,
  },
  {
    name: 'Investor Conference',
    category: 'investorConference',
    userEnabled: true,
  },
  {
    name: 'Investor Day',
    category: 'investorDay',
    userEnabled: true,
  },
  {
    name: 'SEC Filing Deadline',
    color: '#bbbbbb',
    category: 'SECFiling',
    userEnabled: true,
  },
  {
    name: '13F Filing',
    color: '#c8c8c8',
    category: '13fFiling',
    userEnabled: true,
  },
  {
    name: 'N-PX Deadline',
    color: '#bbbbbb',
    category: 'npxDeadline',
    userEnabled: true,
  },
  {
    name: '14a-8 Deadline',
    color: '#979797',
    category: '14a_8Deadline',
    userEnabled: true,
  },
  {
    name: 'Board Meeting',
    category: 'boardMeetings',
    userEnabled: true,
  },
  {
    name: 'Earnings Call',
    category: 'earnings',
    userEnabled: true,
    color: '#9cff0e',
  },
  {
    name: 'Holiday',
    color: '#01a614',
    category: 'holiday',
    userEnabled: true,
  },
  {
    name: 'Proxy Filing',
    category: 'proxyFiling',
  },
  {
    name: 'ISS/GL Engagement',
    category: 'issGlEngagement',
    userEnabled: true,
  },
  {
    name: 'ISS/GL Publication',
    category: 'issGlPublication',
  },
  {
    name: 'Annual Meeting',
    category: 'annualMeeting',
  },
  {
    name: 'Review Feedback',
    category: 'reviewFeedback',
    userEnabled: true,
    color: '#efc9a6',
  },
  {
    name: 'Outreach',
    category: 'outReach',
    userEnabled: true,
  },
  {
    name: 'Develop Engagement Materials',
    category: 'developEngagementMaterial',
    userEnabled: true,
  },
  {
    name: 'Other',
    color: '#000000',
    category: 'other',
    userEnabled: true,
  },
];

export const LANDING_PAGE_URL = 'https://shrubplatforms.com';

// ENV Variables below
export const SHRUB_API_URL = import.meta.env.VITE_SHRUB_API_URL!;
export const COGNITO_USER_POOL_ID = import.meta.env.VITE_COGNITO_USER_POOL_ID!;
export const COGNITO_USER_POOL_CLIENT_ID = import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID!;
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT!;
export const LIVE_SESSION_ID = import.meta.env.VITE_LIVE_SESSION_ID!;
