import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../reduxStore.ts';
import { IMetaTag } from '../../typescript/IMetaTag.ts';
import { RequestStates } from '../../typescript/basicTypes.ts';
import { getMetaTags } from '../../services/metaTags.service.ts';
import { resetOrganization, resetRedux } from '../custom/customActions.ts';

type InitialState = { tags: IMetaTag[]; fetching: { [key: string]: RequestStates } };

const initialState: InitialState = {
  tags: [],
  fetching: {},
};

export const fetchOrganizationTags = createAsyncThunk(
  'metaSlice/fetchOrganizationTags',
  async (type: string | undefined, { rejectWithValue, getState }) => {
    const { organizationSlice }: any = getState();
    try {
      return getMetaTags(organizationSlice.organization._id, type);
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (type, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { metaSlice }: any = getState();
      if (metaSlice.fetching[type || 'all'] === RequestStates.pending) {
        return false;
      }
    },
  },
);

const metaSlice = createSlice({
  name: 'metaSlice',
  initialState: initialState,
  reducers: {
    addMetaTag: (state, action) => {
      state.tags.push(action.payload);
    },
    removeMetaTag: (state, action) => {
      state.tags = state.tags.filter((t) => t._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationTags.fulfilled, (state, action) => {
        if (action.meta.arg) {
          const temp = state.tags.filter((t) => t.type !== action.meta.arg);
          state.tags = temp.concat(action.payload);
        } else {
          state.tags = action.payload;
        }

        state.fetching[action.meta?.arg || 'all'] = RequestStates.idle;
      })
      .addCase(fetchOrganizationTags.pending, (state, action) => {
        state.fetching[action.meta?.arg || 'all'] = RequestStates.pending;
      })
      .addCase(fetchOrganizationTags.rejected, (state, action) => {
        state.fetching[action.meta?.arg || 'all'] = RequestStates.idle;
      });

    // Reset Redux
    builder.addCase(resetRedux, () => initialState);
    builder.addCase(resetOrganization, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { addMetaTag, removeMetaTag } = metaSlice.actions;

// Selectors
export const selectMetaTags = (state: RootState) => state.metaSlice.tags;
export const selectMetaTagsByType = createSelector(
  [selectMetaTags, (_, type) => type],
  (tags, type) => (type ? tags.filter((tag) => tag.type === type) : tags),
);

export default metaSlice.reducer;
