import { FormInput } from '../../../components/basic/FormInput.tsx';
import { Button } from 'react-daisyui';
import { useState } from 'react';
import { patchUser } from '../../../services/user.service.ts';
import { useAppDispatch } from '../../../redux/reduxStore.ts';
import { fetchUser } from '../../../redux/slices/userSlice.ts';

export const PersonalInformationForm = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ firstName: '', lastName: '' });
  const dispatch = useAppDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await patchUser({ ...form });
      await dispatch(fetchUser()).unwrap();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className={'text-3xl'}>User Details</h1>

      <p className={'mt-4 mb-2 text-sm'}>Please enter your details</p>

      <form className={'w-full'} onSubmit={handleSubmit}>
        <FormInput
          fullWidth={true}
          label={'First Name'}
          type={'text'}
          required={true}
          placeholder={'First Name'}
          autoComplete={'given-name'}
          value={form.firstName}
          onChange={(e) => setForm({ ...form, firstName: e.target.value })}
        />
        <FormInput
          fullWidth={true}
          label={'Last Name'}
          type={'text'}
          required={true}
          placeholder={'Last Name'}
          autoComplete={'family-name'}
          value={form.lastName}
          onChange={(e) => setForm({ ...form, lastName: e.target.value })}
        />
        <Button
          color={'primary'}
          type={'submit'}
          fullWidth={true}
          className={'mt-4 text-white'}
          loading={loading}>
          Next
        </Button>
      </form>
    </>
  );
};
