import axios from 'axios';
import { SHRUB_API_URL } from '../utils/constants.ts';
import { ICalendarEvent } from '../typescript/ICalendar.ts';

export const getSECFilingDates = async (orgId: string) => {
  const { data } = await axios.get(`${SHRUB_API_URL}/meta/${orgId}/sec-filing-deadlines`);
  return data as ICalendarEvent[];
};

export const getBankHolidays = async () => {
  const { data } = await axios.get(`${SHRUB_API_URL}/meta/bank-holidays`);
  return data as ICalendarEvent[];
};

export const postTextExtraction = async (formData: FormData) => {
  const { data } = await axios.post(`${SHRUB_API_URL}/meta/text-extraction`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};
